.payment-methods {
  list-style: none;
  margin: 0;
  padding: 0;
}

.payment-method {
  overflow: hidden;

  & > .custom-radio {
    padding-left: 0;
  }

  &-header {
    position: relative;
    display: block;
    font-size: rem(14px);
    cursor: pointer;
    padding: rem(7px) rem(10px);
    border: 1px solid transparent;
    padding-left: $custom-control-gutter * 2 + $custom-control-indicator-size;
  }

  &-body {
    display: block;
    height: 0;
    overflow: hidden;
    opacity: 0;
    position: relative;
    top: -rem(10px);
    margin-left: rem(36px);
    transition: all 0.3s ease-in-out;
    @include media-breakpoint-down(sm) {
      margin-left: rem(16px);
    }
  }

  .custom-radio {
    & > .custom {
      &-control {
        padding-left: 0;

        &-label {
          &:before {
            left: .6rem;
            top: 50%;
            transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
          }
        }
      }
    }

    [type="radio"] {
      &:checked {

        & ~ .custom-control-label {
          background-color: #F7F7F7;
          border-color: #E4E4E4;

          &:after {
            left: .6rem;
            top: 50%;
            transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
          }
        }

        & ~ .payment-method-body {
          display: block;
          margin-top: rem(16px);
          opacity: 1;
          height: auto;
          top: 0;
        }
      }
    }
  }

  &-list {
    font-size: rem(14px);
  }
}