﻿@import '../utils/mixins';
@import '../variables';

/*

-----------------------------------------
  1.00 Cards
-----------------------------------------

*/

.card {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: $white;
  border: none;
  border-radius: $border-radius;
  box-shadow: none;

  &.card--h-auto {
    height: auto;
  }

  &-title-wrapper {
    border-bottom: $table-border-width solid $table-border-color;
  }
}

.card-dashboard {
  display: flex;
  flex-direction: column;
  padding: rem(24px);
  padding-top: rem(20px);
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: block;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    height: 100%;
  }

  &__footer {
  }
}

.card-dashboard-small {
  padding: rem(24px);
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: rem(16px);

    a,
    span {
      font-size: rem(16px);
      color: $gray-900;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    i {
      font-size: rem(24px);
      margin-left: rem(4px);
    }
  }

  &__content {
    display: block;
    padding-right: 110px;

    .values {
      display: flex;
      align-items: flex-end;

      h1 {
        padding: 0;
        margin: 0;
        line-height: 1;
      }

      h4 {
        padding: 0;
        margin: 0;
        color: $gray-600;
        line-height: 1;
        padding-bottom: rscale(5px);
      }
    }

    .zero-message {
      display: flex;
      align-items: center;
      min-height: rem(61px);
      padding: 0;
      margin: 0;
      font-size: rem(12px);
      color: $gray-600;
    }
  }

  &__svg {
    display: block;
    position: absolute;
    bottom: rem(16px);
    right: rem(24px);
  }

  &--one-liner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: rem(16px) rem(24px);

    .card-dashboard-small__svg {
      display: block;
      position: relative;
      bottom: auto;
      right: auto;

      svg {
        width: rem(46px);
        height: auto;
      }
    }

    .card-dashboard-small__header {
      margin-bottom: 0;
      width: 100%;
      flex: 0 1 auto;
      padding-left: rem(16px);
    }

    .card-dashboard-small__content {
      display: block;
      padding-right: 0;
      text-align: right;

      @include media-breakpoint-up(md) {
        white-space: nowrap;
      }

      .values {
        h1 {
          font-size: rscale(48px);
        }

        h4 {
          font-size: rem(18px);
        }
      }
    }
  }
}

.meetings {
  &-header {
    padding: rem(18px) rem(36px);
    background-color: $white;
    border-bottom: 1px solid #EFEFEF;

    .control-label {
      color: #000000 !important;
      font-size: 1rem !important;
      margin-bottom: 0;
      margin-right: 0.5rem !important;
    }

    @media only screen and (max-width: 767px) {
      padding: rem(12px) rem(20px);
      li:not(#filter-button):not(#filter-search-button) {
        border-bottom: 1px solid #F7F7F7;
        padding-bottom: rem(10px);
        flex-direction: column;
        align-items: flex-start !important;
        text-align: left;
      }
    }
  }

  &-list {
    padding: rem(24px) rem(36px);
    background-color: $white;
    @media only screen and (max-width: 767px) {
      padding: rem(20px) rem(20px);
    }
  }
}

.card-bookings-booked {
  display: flex;
  flex-direction: column;
  padding: rem(20px);

  &__header {
    display: flex;
    justify-content: space-between;

    .title-wrapper {
      position: relative;
      max-width: calc(100% - #{$input-btn-height});

      .higlight {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: rem(4px);
        border-radius: 4px;
        background: $gray-200;
      }

      .title {
        padding-left: rem(12px);

        a {
          display: block;
          color: $gray-900;
          text-decoration: underline;
          text-decoration-color: transparent;

          &:hover,
          &:active,
          &:focus {
            text-decoration-color: currentColor;
          }

          h4 {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            margin-bottom: rem(4px);
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    padding: rem(20px) 0;
    height: 100%;
    min-height: rem(82px);

    & > time {
      display: block;
      font-size: rem(14px);

      span {
        display: block;
        color: $gray-700;
      }
    }

    & > i {
      font-size: rem(24px);
      margin: 0 rem(12px);
    }
  }

  &__footer {
    padding-top: rem(20px);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .resources {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: rem(24px);
      padding-right: rem(16px);

      & > i {
        display: block;
        position: absolute;
        top: rem(2px);
        left: 0;
        background: $gray-200;
        width: rem(16px);
        height: rem(16px);
        border-radius: rem(4px);
        color: $gray-600;
        font-size: rem(16px);
        margin-right: rem(8px);
      }

      & > span {
        padding-right: rem(4px);
        font-size: rem(14px);
        color: $gray-700;

        &:not(:last-child) {
          &:after {
            content: ',';
          }
        }
      }
    }

    .status {
      display: flex;
      align-items: center;
      height: $input-btn-height-xs;
      padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
      padding-left: $input-btn-padding-x-xs/2;
      border-radius: 0.5rem;
      line-height: $btn-line-height;
      font-size: $input-btn-font-size-xs;
      font-weight: $btn-font-weight;
      background: $gray-200;
      color: $gray-900;
      white-space: nowrap;
      cursor: default;

      & > i {
        margin-right: $input-btn-padding-x-xs / 2;
        font-size: $input-btn-icon-size-xs;
      }

      &.pending {
        background: rgba($orange, 0.2);
      }

      &.confirmed {
        background: rgba($green, 0.2);
      }
    }
  }
}

.card-bookings-available {
  background-color: #F7F7F7;

  &__main {
    position: relative;
    height: 100%;
  }

  &__image {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    width: rem(220px);
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: rem(30px) rem(24px) rem(24px) rem(264px);
    min-height: rem(260px);
  }

  &__title {
    position: relative;
    padding-left: rem(12px);

    .higlight {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: rem(4px);
      border-radius: 4px;
      background: $gray-200;
    }

    .title-wrapper {
      & > a {
        display: block;
        color: #000;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: transparent;

        &:hover,
        &:active,
        &:focus {
          text-decoration-color: currentColor;
        }

        h4 {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          margin-bottom: rem(4px);
          font-weight: bold;
        }
      }
    }
  }

  &__status {
    position: absolute;
    top: rem(4px);
    right: rem(4px);

    .tag {
      &.available {
        background: $green;
        color: $white;
      }

      &.unavailable {
        background: $red;
        color: $white;
      }
    }
  }

  &__description {
    margin-top: rem(12px) !important;
    margin-bottom: 0;
    font-size: rem(14px);
    line-height: 1.4;
    color: $gray-700;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: rem(16px);
    }
  }

  &__spacer {
    flex: 1 1 100%;
  }

  &__features {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(16px);
    margin-bottom: rem(24px);
    line-height: 1.33;
    color: $main-menu-item-link-active;

    & > i {
      display: block;
      position: absolute;
      top: rem(2px);
      left: 0;
      background: $gray-200;
      width: rem(16px);
      height: rem(16px);
      border-radius: rem(4px);
      color: $gray-600;
      font-size: rem(16px);
      margin-right: rem(8px);
    }

    & > span {
      padding-right: rem(4px);
      font-size: rem(14px);

      &:not(:last-child):not(:first-child) {
        &:after {
          content: ',';
        }
      }
    }
  }

  &__footer {
    overflow: hidden;
    padding: 0 rem(8px);
  }

  @include media-breakpoint-down(sm) {
    .card-bookings-available__main {
      .card-bookings-available__content {
        position: relative;
        padding: rem(20px) rem(20px) rem(20px) rem(244px);
        min-height: 1px;

        .card-bookings-available__status {
          top: rem(-32px);
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .card-bookings-available__main {
      .card-bookings-available__image {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        border-radius: $border-radius;
        width: 100%;
        height: rem(180px);
      }

      .card-bookings-available__content {
        padding: rem(20px);
      }

      .book-now-button {
        .btn {
          width: 100%;
        }
      }

      height: auto;
    }
  }

  &:not(.card-bookings-map) {
    .higlight {
      display: none;
    }

    .card-bookings-available__title {
      padding-left: 0;
    }
  }
}

.card-bookings-available.card-bookings-map {
  display: inline-block;
  height: rem(152px);
  width: rem(455px);
  margin: 0 rem(12px);
  transform: translateY(0);
  transition: transform 0.2s;

  @include media-breakpoint-down(sm) {
    height: rem(100px);
    width: 75vw;
  }

  &.active {
    transform: translateY(-20px);
    transition: transform 0.2s;
  }

  .card-bookings-available__main {
    height: 100%;

    .card-bookings-available__title {
      @include media-breakpoint-down(sm) {
        top: 5px;
      }

      .list-tags {
        @include media-breakpoint-down(sm) {
          flex-wrap: nowrap;
          overflow: scroll;
          margin-right: 10px;
        }
      }
    }

    .card-bookings-map__image {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      background-color: $gray-200;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
      width: rem(120px);
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        height: rem(100px);
        width: rem(100px);
      }
    }

    .card-bookings-map__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: rem(15px) rem(24px) rem(24px) rem(150px);
      min-height: rem(175px);

      @include media-breakpoint-down(sm) {
        min-height: rem(100px);
        padding: rem(20px) 0 rem(20px) rem(116px);
      }
    }
  }
}

.card-event,
.card-course {
  &__image {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: $border-radius;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    width: rem(188px);
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: rem(24px) rem(24px) rem(20px) rem(212px);
    min-height: rem(308px);
  }

  &__content {
    display: block;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: rem(24px);
  }

  &__title {
    font-size: rem(24px);
    font-weight: 500;
    color: $gray-900;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    //flex: 0 0 auto;
    &:hover,
    &:focus,
    &:active {
      color: $gray-900;
    }
  }

  &__tags {
    margin-top: rem(8px) !important;

    a {
      color: $black;
    }
  }

  &__description {
    margin-top: rem(8px) !important;
    margin-bottom: 0;
    font-size: rem(14px);
    line-height: 1.4;
    color: $gray-700;
    max-height: rem(42px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__time {
    display: flex;
    align-items: center;
    margin-top: rem(16px);
    font-size: rem(14px);

    & > time {
      span {
        display: block;
        color: $gray-700;
      }
    }

    & > i {
      font-size: rem(24px);
      margin: 0 rem(12px);
    }
  }

  &__location {
    margin-top: rem(16px);
    font-size: rem(14px);
  }

  &__calendar {
    margin-top: rem(16px);
    font-size: rem(14px);
  }

  &__attendees {
    display: flex;
    align-items: center;
    font-size: rem(14px);

    i {
      font-size: rem(24px);
      margin-right: rem(2px);
    }

    span {
      color: $gray-700;
    }
  }

  &__price {
    font-size: rem(18px);
    font-weight: 500;

    span {
      display: flex;

      &.bought {
      }

      &.free {
        background: $green;
        color: $white;
      }
    }
  }

  &--xs {
    height: auto;
    min-height: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;

    .card-event__image,
    .card-course__image {
      width: rem(114px);
    }

    .card-event__main,
    .card-course__main {
      padding-left: rem(130px);
      padding-top: 0;
      padding-bottom: 0;
      min-height: rem(144px);
    }

    .card-event__footer,
    .card-course__footer {
      margin-top: rem(12px);
    }

    .card-event__title,
    .card-course__title {
      font-size: rem(18px);
    }

    .card-event__tags,
    .card-course__tags {
      display: none;
    }

    .card-event__description,
    .card-course__description {
      display: none;
    }

    .card-event__time,
    .card-course__time {
      margin-top: rem(12px);
    }

    .card-event__price,
    .card-course__price {
      font-size: rem(14px);
    }
  }

  &--sm {
    .card-event__image,
    .card-course__image {
      width: rem(132px);
    }

    .card-event__main,
    .card-course__main {
      padding-left: rem(156px);
      min-height: rem(216px);
    }

    .card-event__footer,
    .card-course__footer {
      margin-top: rem(16px);
    }

    .card-event__title,
    .card-course__title {
      font-size: rem(18px);
    }

    .card-event__tags,
    .card-course__tags {
      display: none;
    }

    .card-event__description,
    .card-course__description {
      display: none;
    }

    .card-event__time,
    .card-course__time {
      margin-top: rem(12px);
    }

    .card-event__price,
    .card-course__price {
      font-size: rem(14px);
    }
  }

  &--md {
    @include media-breakpoint-down(sm) {
      position: relative;
      display: block !important;
      width: 100%;
      height: 100%;

      .card-event__image,
      .card-course__image {
        position: relative;
        width: 100%;
        height: rem(240px);
      }

      .card-event__main,
      .card-course__main {
        height: auto;
        padding-left: rem(24px);
        min-height: rem(1px);
      }
    }
  }

  &--lg {
    height: auto;
    min-height: rem(380px);

    @include media-breakpoint-only(md) {
      min-height: rem(340px);
    }

    @include media-breakpoint-down(sm) {
      min-height: 0;
    }

    .card-event__image,
    .card-course__image {
      width: rem(320px);

      @include media-breakpoint-only(md) {
        width: rem(240px);
      }

      @include media-breakpoint-down(sm) {
        position: relative;
        width: 100%;
        height: rem(240px);
      }
    }

    .card-event__main,
    .card-course__main {
      padding-left: rem(352px);
      min-height: rem(380px);

      @include media-breakpoint-only(md) {
        padding-left: rem(256px);
        min-height: rem(340px);
      }

      @include media-breakpoint-down(sm) {
        padding: rem(16px);
        min-height: 0;
      }
    }

    .card-event__attendees {
      display: none;
    }

    .card-event__title,
    .card-course__title {
      font-size: rem(32px);

      @include media-breakpoint-down(md) {
        font-size: rem(28px);
        line-height: 1.2;
      }
    }

    .card-event__description,
    .card-course__description {
      display: none;
    }

    .card-event__time,
    .card-course__time {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      & > time {
        &:not(:last-child) {
          @include media-breakpoint-down(sm) {
            margin-bottom: rem(16px);
          }
        }
      }

      & > i {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}

.card-course {
  &__lessons-count,
  &__skill-level {
    margin-top: rem(8px);
  }

  &__attendees-count,
  &__lessons-count,
  &__skill-level {
    .icon {
      font-size: rem(24px);
      margin-right: 4px;
    }

    span {
      font-size: rem(14px);
    }
  }

  &__status {
    font-weight: 500;

    .tag.yellow {
      background-color: #f8f0ce;
    }

    .tag.green {
      background-color: #d4f1df;
    }
  }

  &__author {
    .user {
      display: flex;
      align-items: center;
      font-size: rem(14px);
      color: $gray-900;

      .avatar {
        margin-right: rem(8px);
      }
    }
  }
}

.card-community-board {
  &--small {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: rem(20px) rem(24px);
    min-height: rem(216px);

    &__header {
      .user {
        display: flex;
        align-items: center;
        font-size: rem(14px);
        color: $gray-700;

        .avatar {
          margin-right: rem(8px);
        }
      }
    }

    &__content {
      flex: 1 1 100%;
      height: 100%;
      margin-top: rem(8px);

      .preview {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      a {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: rem(93px);
        font-size: rem(24px);
        font-weight: 500;
        line-height: 1.33;
        color: $gray-900;
      }
    }

    &__footer {
      display: flex;
      align-items: flex-end;
      margin-top: rem(16px);

      .reputation {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: rem(12px);
        }

        button,
        span {
          font-size: rem(14px);
        }

        & > i {
          font-size: rem(24px);
          margin-right: rem(4px);
        }

        & > span {
          cursor: default;
          color: $gray-700;
        }
      }
    }
  }
}

.card-blog-post {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: rem(448px);

  @include media-breakpoint-only(md) {
    min-height: rem(410px);
  }

  &__image {
    display: block;
    border-radius: $border-radius;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: rem(216px);
    min-height: rem(216px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1 1 100%;
    padding: 0 rem(24px) rem(20px) rem(24px);
  }

  &__main {
  }

  &__author {
    margin-top: rem(20px);

    .user {
      display: flex;
      align-items: center;
      font-size: rem(14px);
      color: $gray-700;

      .avatar {
        margin-right: rem(8px);
      }
    }
  }

  &__meta {
    display: flex;
    align-items: center;
    margin-top: rem(16px);
    font-size: rem(14px);
    color: $gray-700;

    .category {
      margin-right: rem(8px);

      span {
        &:not(:last-child) {
          padding-right: rem(4px);

          &:after {
            content: ', ';
          }
        }

        a {
          color: $gray-700;
          font-size: rem(14px);
        }
      }

      &:after {
        content: '•';
        margin-left: rem(8px);
      }
    }
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: rem(62px);
    font-size: rem(24px);
    font-weight: 500;
    line-height: 1.33;
    margin-top: rem(12px);
    color: $gray-900;

    &:hover,
    &:active,
    &:focus {
      color: $gray-900;
    }
  }

  &__description {
    margin-top: rem(8px);
    margin-bottom: 0;
    font-size: rem(14px);
    line-height: 1.4;
    color: $gray-700;
    max-height: rem(42px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.card-basket-item {
  height: auto;
  padding: rem(8px) rem(20px);
  margin-bottom: rem(15px) !important;

  &__header {
    .alert {
      margin-top: rem(4px);
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &-l {
    }

    &-r {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-direction: row;
      }
    }
  }

  &__media {
    display: flex;
    margin-top: rem(12px);
    margin-bottom: rem(12px);

    &__image {
      margin-right: rem(12px);
      border-radius: $border-radius;
    }

    &__higlight {
      width: rem(4px);
      height: rem(48px);
      border-radius: rem(4px);
      margin-right: rem(8px);
    }

    &__text {
      &__title {
        margin-bottom: rem(4px);
      }

      &__features {
      }

      &__times {
        font-size: rem(14px);
        color: $gray-700;
      }
    }
  }

  &__options {
    margin-bottom: rem(12px);

    .event-options {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-top: rem(8px);
        }

        i {
          font-size: rem(20px);
          margin-right: rem(8px);
          color: $gray-600;
        }

        span {
          white-space: nowrap;
          font-size: rem(14px);
          color: $gray-700;
        }
      }
    }

    .booking-options {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: rem(24px);
      padding-right: rem(16px);

      & > i {
        display: block;
        position: absolute;
        top: rem(2px);
        left: 0;
        background: $gray-200;
        width: rem(16px);
        height: rem(16px);
        border-radius: rem(4px);
        color: $gray-600;
        font-size: rem(16px);
        margin-right: rem(8px);
      }

      & > span {
        padding-right: rem(4px);
        font-size: rem(14px);
        color: $gray-700;

        &:not(:last-child) {
          &:after {
            content: ',';
          }
        }
      }
    }

    .contract-options {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-top: rem(8px);
        }

        i {
          font-size: rem(20px);
          margin-right: rem(8px);
          color: $gray-600;
        }

        span {
          white-space: nowrap;
          font-size: rem(14px);
          color: $gray-700;
        }
      }
    }
  }

  &__price {
    font-size: rem(18px);
    line-height: 1;
    font-weight: 500;
    margin-top: rem(12px);
    margin-bottom: rem(6px);
    text-align: right;

    @include media-breakpoint-down(sm) {
      margin-top: rem(6px);
      margin-bottom: rem(10px);
    }

    small {
      display: block;
      font-size: rem(12px);
      color: $gray-600;
      margin-top: rem(6px);
    }
  }

  &__actions {
    margin-bottom: rem(8px);

    .btn:not(:last-child) {
      margin-right: rem(4px);
    }
  }
}

.card-directory-member-team {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(32px);

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    color: $gray-900;
    text-decoration: none;

    & > img {
    }

    & > h5 {
      margin: rem(16px) 0 rem(4px) 0;
      text-decoration: underline;
      text-decoration-color: transparent;
      @include transition();
    }

    & > small {
      font-size: rem(14px);
      color: $gray-600;
    }

    .badge {
      display: flex;
      align-items: center;
      position: absolute;
      top: rem(18px);
      left: rem(18px);
      font-weight: 500;
      font-size: rem(12px);
      background: $gray-200;
      color: $gray-900;
      border-radius: rem(4px);

      i {
        font-size: rem(16px);
        margin-right: rem(4px);
      }

      &.right {
        left: auto;
        right: rem(18px);
      }
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: $gray-900;

      & > h5 {
        text-decoration-color: $gray-900;
      }
    }
  }

  &__social {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: rem(20px);
    flex-wrap: wrap;

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(28px);
        width: rem(28px);
        margin: rem(2px);
        color: $gray-600;
        text-decoration: none !important;

        i {
          font-size: rem(24px);
          text-decoration: none !important;
        }

        &:hover,
        &:active,
        &:focus {
          color: $gray-900;
          text-decoration: none !important;
        }
      }
    }
  }
}

.card-product {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__image {
    border-radius: $border-radius;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    padding-top: 54%;
  }

  &__content {
    padding: rem(24px);
    padding-top: rem(16px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid #EFEFEF;

    @include media-breakpoint-down(xl) {
      padding: rem(20px);
      padding-top: rem(16px);
    }

    &__header {
      flex: 1 1 auto;
    }

    &__footer {
      flex: 0 0 auto;
    }
  }
}

.card-perk-discount {
  display: flex;
  flex-direction: row;
  padding: rem(24px);

  &__wrapper-image {
    position: relative;
    flex: 0 0 rem(100px);

    @include media-breakpoint-down(sm) {
      flex: 0 0 rem(64px);
    }
  }

  &__wrapper-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 auto;
    padding-left: rem(24px);
    height: 100%;
  }

  &__image {
    display: block;
    position: relative;

    img {
      display: block;
      object-fit: cover;
      width: rem(100px);
      height: rem(100px);
      border-radius: $border-radius;

      @include media-breakpoint-down(sm) {
        width: rem(64px);
        height: rem(64px);
      }
    }

    .badge {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: rem(-5px);
      right: rem(-5px);
      border-radius: 50%;
      background: $gray-200;
      padding: rem(4px);

      @include media-breakpoint-down(sm) {
        padding: rem(2px);
      }

      i {
        font-size: rem(24px);
        color: $gray-600;

        @include media-breakpoint-down(sm) {
          font-size: rem(16px);
        }
      }
    }
  }
}

.card-video-room {
  display: flex;
  flex-direction: column;

  &__image {
    position: relative;
    display: block;
    border-radius: $border-radius;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 54%;

    .tag {
      position: absolute;
      top: rem(16px);
      right: rem(16px);
      background: $white !important;
      color: $gray-900 !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1 1 100%;
    padding: rem(24px);
    padding-top: rem(16px);

    @include media-breakpoint-down(md) {
      padding: rem(16px);
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: rem(24px);
  }
}

.card-support {
  &__head,
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 rem(8px);

    @include media-breakpoint-down(lg) {
      display: block;
    }

    .title,
    .meta {
      & > div {
        padding: rem(8px);

        @include media-breakpoint-down(sm) {
          padding: rem(4px) rem(8px);
        }
      }
    }

    .title {
      flex: 1 1 auto;
    }

    .meta {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 0 0 60%;

      @include media-breakpoint-down(lg) {
        flex: 0 0 100%;
      }

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }

      & > div:nth-child(1) {
        flex: 0 0 23%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 50%;
          order: 3;
        }
      }

      & > div:nth-child(2) {
        flex: 0 0 27%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
        }
      }

      & > div:nth-child(3) {
        flex: 0 0 26%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 100%;
        }
      }

      & > div:nth-child(4) {
        flex: 0 0 10%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 20%;
          order: 4;
        }
      }

      & > div:nth-child(5) {
        flex: 0 0 14%;

        @include media-breakpoint-down(sm) {
          flex: 0 0 30%;
          order: 5;
        }
      }
    }
  }

  &__head {
    @include media-breakpoint-down(lg) {
      .title {
        font-weight: 500;
      }

      .meta {
        font-size: rem(14px);
      }
    }

    @include media-breakpoint-down(sm) {
      .meta {
        padding-bottom: rem(16px);

        & > div {
          padding: 0 rem(8px);
        }
      }
    }
  }

  &__content {
    margin-bottom: rem(8px);
    text-decoration: none;
    color: $gray-900;
    padding: rem(8px);

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $gray-900;
      box-shadow: $box-shadow;
    }

    .title {
      & > div {
        span {
          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.card-location {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: $box-shadow;

  &__image {
    display: block;
    border-radius: $border-radius;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    padding-top: 54%;
  }

  &__content {
    padding: rem(24px);
    padding-top: rem(16px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include media-breakpoint-down(xl) {
      padding: rem(20px);
      padding-top: rem(16px);
    }

    &__header {
      flex: 1 1 auto;
    }

    &__footer {
      flex: 0 0 auto;
    }
  }
}


.card-item {
  &-wrapper {
    @extend .funnel-steps-wrapper;
  }

  &-header {
    @extend .funnel-steps-header;

    &-title {
      @extend .funnel-steps-header-title;

      &-dashboard {
        font-size: rem(28px);
        font-weight: 400;
        margin: rem(10px) 0;
        @include media-breakpoint-only(md) {
          font-size: rem(18px);
        }
        @include media-breakpoint-down(sm) {
          font-size: rem(16px);
        }
        @media only screen and (max-width: 480px) {
          margin-bottom: rem(15px);
        }
      }
    }
  }

  &-body {
    @extend .funnel-steps-body;
  }
}