﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  2.00 Header
-----------------------------------------
*/

.inside-iframe,
.in-ms-teams {
  .site-footer {
    display: none !important;
  }
  .login__footer {
    display: none !important;
  }
  .login__content {
    min-height: calc(100vh) !important;
  }
}

.site-footer {
  background: $white;
  border-top: 2px solid $body-bg;
  padding: rem(72px) 0 rem(48px) 0;
  text-align: initial;

  @include media-breakpoint-down(sm) {
    padding-bottom: rem(32px);
  }

  .list-unstyled {
    li {
      padding-top: rem(8px);

      a {
        color: $gray-900;
      }
    }
  }
}
