﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  2.00 Content
-----------------------------------------
*/

body {
  text-align: initial;

  &.li {
    overflow: hidden;

    .content {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 5rem);
      overflow: auto;

      @include media-breakpoint-up(xl) {
        @include scrollbar($body-bg);
      }
    }
  }
}

.content {
  &.content--custom {
  }

  &.content--home {
  }

  &.content--about {
  }

  &.content--dashboard {
  }

  &.content--signup {
  }

  //&.content--fullpage {
  //  min-height: 100vh !important;
  //}

  &.capacity {
  }

  &.board {
  }

  &.faq {
  }
}
