.help-item {
  background-color: $white;

  &:not(:last-child) {
    margin-bottom: rem(8px);
  }

  i {
    color: $main-site-color;
    font-size: rem(23px);
    margin-right: rem(18px);
  }

  &-name {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: rem(16px);
    padding: rem(16px) rem(18px);
    text-decoration: none;
    color: #4B4B4B;

    &:hover {
      text-decoration: none;
      color: var(--link-color);
    }

    @include media-breakpoint-down(sm) {
      align-items: start;
      font-size: rem(14px);
    }
  }
}