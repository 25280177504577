.loader {
  display: block;
  zoom: 1.1; /* Increase this for a bigger symbol*/
  width: 16px;
  height: 16px;
  margin: auto;
  animation: wait 0.8s steps(1, start) infinite;
  background: linear-gradient(
      0deg,
      $gray-800 1px,
      transparent 0,
      transparent 8px,
      $gray-800 8px
    ),
    /* 6  */
      linear-gradient(
        90deg,
        $gray-800 1px,
        $gray-800 0,
        $gray-800 3px,
        $gray-800 3px
      ),
    linear-gradient(
      0deg,
      $gray-800 1px,
      transparent 0,
      transparent 8px,
      $gray-800 8px
    ),
    /* 5  */
      linear-gradient(
        90deg,
        $gray-800 1px,
        $gray-800 0,
        $gray-800 3px,
        $gray-800 3px
      ),
    linear-gradient(
      0deg,
      $gray-800 1px,
      transparent 0,
      transparent 8px,
      $gray-800 8px
    ),
    /* 4  */
      linear-gradient(
        90deg,
        $gray-800 1px,
        $gray-800 0,
        $gray-800 3px,
        $gray-800 3px
      ),
    linear-gradient(
      0deg,
      $gray-800 1px,
      transparent 0,
      transparent 10px,
      $gray-800 10px
    ),
    /* 3  */
      linear-gradient(
        90deg,
        $gray-800 1px,
        $gray-800 0,
        $gray-800 3px,
        $gray-800 3px
      ),
    linear-gradient(
      0deg,
      $gray-800 1px,
      transparent 0,
      transparent 15px,
      $gray-800 15px
    ),
    /* 2  */
      linear-gradient(
        90deg,
        $gray-800 1px,
        $gray-800 0,
        $gray-800 3px,
        $gray-800 3px
      ),
    linear-gradient(
      0deg,
      $gray-800 1px,
      transparent 0,
      transparent 15px,
      $gray-800 15px
    ),
    /* 1  */
      linear-gradient(
        90deg,
        $gray-800 1px,
        $gray-800 0,
        $gray-800 3px,
        $gray-800 3px
      );

  background-repeat: no-repeat;

  background-size: 4px 9px, /* 6 */ 4px 9px, 4px 9px, /* 5 */ 4px 9px, 4px 9px,
    /* 4 */ 4px 9px, 4px 11px, /* 3 */ 4px 11px, 4px 16px, /* 2 */ 4px 16px,
    4px 16px, /* 1 */ 4px 16px;

  background-position: -4px 3px, -4px 3px, -4px 3px, -4px 3px, -4px 3px,
    -4px 3px, -4px 2px, -4px 2px, -4px 0, -4px 0, -4px 0, -4px 0;
}

@keyframes wait {
  12.5% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, 0, /* 1 */ 0;
  }
  25% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, 0, /* 2 */ 0, 6px, /* 1 */ 6px;
  }
  37.5% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, 0, /* 3 */ 0, 6px, /* 2 */ 6px, 12px, /* 1 */ 12px;
  }
  50% {
    background-position: -4px, /* 6 */ -4px, -4px, /* 5 */ -4px, 0, /* 4 */ 0,
      6px, /* 3 */ 6px, 12px, /* 2 */ 12px, -4px, /* 1 */ -4px;
  }
  62.5% {
    background-position: -4px, /* 6 */ -4px, 0, /* 5 */ 0, 6px, /* 4 */ 6px,
      12px, /* 3 */ 12px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
  75% {
    background-position: 0, /* 6 */ 0, 6px, /* 5 */ 6px, 12px, /* 4 */ 12px,
      -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
  87.5% {
    background-position: 6px, /* 6 */ 6px, 12px, /* 5 */ 12px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
  100% {
    background-position: 12px, /* 6 */ 12px, -4px, /* 5 */ -4px, -4px,
      /* 4 */ -4px, -4px, /* 3 */ -4px, -4px, /* 2 */ -4px, -4px, /* 1 */ -4px;
  }
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
