﻿@import '../utils/mixins';
@import '../variables';
/*
-----------------------------------------
  3.00 Events
-----------------------------------------
*/

.event-page-details {
  hr {
    margin: rem(24px) 0;
  }
}

.event-page-social-links {
  padding: rem(20px) rem(24px) rem(8px) rem(24px);

  @include media-breakpoint-down(sm) {
    padding: rem(12px) rem(16px) rem(6px) rem(16px);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: rem(12px) 0;

      a {
        display: inline-flex;
        align-items: center;
        text-decoration: none !important;
        color: $gray-900;

        i {
          font-size: rem(24px);
          width: 32px;
          color: $gray-600;
          @include transition();

          &.fa {
            font-size: rem(20px);
            padding-left: rem(3px);
          }
        }

        span {
          text-decoration: underline;
          text-decoration-color: transparent;
          @include transition();
        }

        &:hover,
        &:active,
        &.focus {
          text-decoration: none !important;

          i {
            text-decoration: none;
            color: $gray-900;
          }

          span {
            text-decoration-color: currentColor;
          }
        }
      }
    }
  }
}

.event-tickets-page-details {
  &__image {
    display: block;
    border-radius: $border-radius;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    width: rem(84px);
    height: rem(84px);
    min-width: rem(84px);
    min-height: rem(84px);
  }
}
