﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 Tables
-----------------------------------------
*/

.table {

  &-item-name {
    grid-area: name;
  }

  &-item-dates {
    grid-area: dates;
  }

  &-item-status-wrapper {
    grid-area: status;
  }

  &-item-status {
    background-color: rgba(12, 193, 155, 0.15);
    color: #0A9A7C;
    padding: rem(4px) rem(12.5px);
    font-size: rem(12px);
    text-align: center;

    &-cancelled {
      color: #B40028;
      background: rgba(254, 130, 113, 0.3);
    }
  }

  .progress {
    min-width: rem(157px);
  }


  & &-item {
    &-name {
      color: $main-site-color;
      font-size: rem(18px);
      font-weight: 600;

      a {
        color: $main-site-color;;
      }

      &:hover {
        a {
          color: customLighten(--bg-scss-darken-lighten-color, 15);
        }
      }
    }

    &-row-cancelled {
      td,
      .table-item-name {
        color: #E4E4E4;

        @include media-breakpoint-down(md) {
          color: #777777;
        }
      }
    }

  }

  th.rotate {
    transform: rotate(-30deg) translateX(15px) translateY(18px);
    transform-origin: bottom left;
    white-space: nowrap;
  }

  tbody {
    tr {
      border-bottom: 1px solid #EFEFEF;
      background-color: $white !important;
    }
  }

  th,
  td {
    font-size: rem(14px);
    padding: rem(18px) rem(36px);
    border: none;

    @include media-breakpoint-down(sm) {
      padding: rem(10px) rem(20px);
    }

    //&:first-child {
    //  border-top-left-radius: $border-radius;
    //  border-bottom-left-radius: $border-radius;
    //}
    //
    //&:last-child {
    //  border-top-right-radius: $border-radius;
    //  border-bottom-right-radius: $border-radius;
    //}

    &[valign='middle'] {
      vertical-align: middle !important;
    }
  }

  > thead {
    background-color: #EFEFEF;

    > tr > th {
      font-weight: 600;
      border: none;
    }
  }

  &-striped {
    tr {
      border: none;
    }

    //tbody {
    //  tr:nth-of-type(odd) {
    //    background: $white;
    //  }
    //
    //  tr:nth-of-type(even) {
    //    background: $gray-100;
    //  }
    //}
  }

  &--settings {
    > tbody {
      > tr {
        border-top: none;

        &.tr-parent {
          border-bottom: 2px solid $gray-100;

          > th,
          > td {
            padding-top: rem(12px);
            background-color: $white;
          }
        }

        &.tr-child {
          > td {
            display: none;
            padding-bottom: rem(24px);
            font-size: rem(14px);
            background-color: $gray-100;
          }

          &.open {
            > td {
              display: table-cell;
            }
          }
        }
      }
    }

    &__handler {
      .caret {
        display: block;
        transform-origin: center center;
        transform: rotate(0);
        margin-right: rem(8px);
        padding-left: 0;
        font-size: rem(18px);
      }

      small {
        font-size: rem(14px) !important;
        line-height: 1.4 !important;
        margin-top: 0 !important;
        padding-left: rem(30px) !important;
        color: $gray-700 !important;
      }

      &.open {
        .caret {
          transform: translateY(-2px) rotate(45deg);
        }
      }
    }

    &__title {
      display: inline-flex;
      align-items: center;
      position: relative;
      text-decoration: none !important;
    }

    &__expiration {
      display: block;
      margin-left: 20px;
      font-size: rem(14px);
      color: $gray-900;
    }

    &__details-list {
      margin: 0 0 0 20px;
      padding: 0;
      list-style-type: none;

      > li {
        margin: 6px 12px;

        small {
          margin: 0 !important;
        }
      }
    }

    &__payment-options {
      .btn {
        display: flex;
      }

      .btn + .btn {
        margin-top: rem(4px);
      }
    }
  }

  &--kpi {
    font-size: rem(14px);

    > thead {
      > tr > th {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    > tbody {
      > tr {
        > th,
        > td {
          padding-top: rem(8px);
          padding-bottom: rem(8px);
          vertical-align: middle;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        > th {
          font-weight: 500;
        }

        > td {
        }
      }
    }
  }

  &--sortable {
    > thead {
      > tr > th {
        cursor: pointer;

        i {
          display: none;
        }

        &:hover,
        &:focus {
          i {
            display: inline;
          }
        }

        &.active {
          background-color: inherit;

          i {
            display: inline;
          }
        }
      }
    }
  }

  &--receipt {
    margin: 0;
    border: 0;

    th {
      font-weight: 400;
      border-bottom: 0;
    }

    th,
    td {
      padding: 15px 0 !important;
    }

    > tbody {
      > tr > th,
      > tr > td {
        font-weight: 500;
      }

      > tr > th {
        font-size: $font-size-base;
      }
    }

    > tfoot {
      > tr > td > b {
        display: block;
        padding: 15px 0;
        font-size: $font-size-base;
        text-transform: uppercase;
      }
    }
  }

  &--invoices {
    margin-bottom: 0;

    > thead {
      > tr > th {
        font-weight: 400;
        color: $gray-900;
        background-color: $gray-200;
        border-bottom-width: 0;
      }
    }

    > tbody {
      > tr {
        > th,
        > td {
          padding-top: 15px;
          padding-bottom: 15px;
          vertical-align: middle;
        }

        &:first-child {
          > th,
          > td {
            border-top: 0;
          }
        }

        &:last-child {
          > th,
          > td {
            padding-bottom: 0;
          }
        }
      }
    }

    i {
      font-size: rem(24px);
    }
  }


  @include media-breakpoint-down(sm) {
    &-custom {

      tr {
        padding: 0;
      }

      thead {
        display: none;
      }

      tbody {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media only screen and (min-width: 1181px) {
  table {
    th:not(:first-child),
    tr td:not(:first-child) {
      //padding-left: 15px;
      //padding-right: 15px;
      //width: 5%;
    }

    th:first-child,
    tr td:first-child {
      width: 12%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1180px) {

  .table-wrap-tablet table,
  .table-wrap-tablet thead,
  .table-wrap-tablet tbody,
  .table-wrap-tablet tfoot,
  .table-wrap-tablet th,
  .table-wrap-tablet td,
  .table-wrap-tablet tr {
    display: block;
  }

  .table-wrap-tablet {
    overflow: unset !important;

    thead {
      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }

    tbody {
      td, th {
        border: none;
        position: relative;
        padding-left: 35% !important;
        white-space: normal;
        text-align: left;

        &:before {
          content: attr(data-title);
          position: absolute;
          left: rem(16px);
          width: 30%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          font-weight: 500;
          font-size: rem(16px);
        }
      }

      tr {
        display: block;
        width: 100%;
        overflow-x: auto;
      }


      tr:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1013.98px) {
  .table-wrap-tablet tbody td:before,
  .table-wrap-tablet tbody th:before {
    left: rem(20px);
  }
}


@media only screen and (min-width: 1024px) {
  .table-wrap-tablet tbody td:before,
  .table-wrap-tablet tbody th:before {
    left: $card-item-header-x;
  }
}

@media (max-width: 767px) {

  .table-wrap table,
  .table-wrap thead,
  .table-wrap tbody,
  .table-wrap tfoot,
  .table-wrap th,
  .table-wrap td,
  .table-wrap tr {
    display: block;
  }

  .table-wrap {
    overflow: unset !important;

    thead {
      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }

    tbody {
      td, th {
        border: none;
        position: relative;
        padding-left: 35% !important;
        white-space: normal;
        text-align: left;

        &:before {
          content: attr(data-title);
          position: absolute;
          left: $card-item-header-sm-x;
          width: 30%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          font-weight: 500;
          font-size: rem(14px);
        }
      }

      tr {
        display: block;
        width: 100%;
        overflow-x: auto;
      }

      tr:not(:last-child) {
        margin-bottom: rem(5px);
      }
    }
  }

  .table tbody tr {
    padding-top: rem(16px);
    padding-bottom: rem(16px);
  }

  .table tbody tr td {
    border: none;
    padding-top: rem(8px) !important;
    padding-bottom: rem(8px) !important;
  }

  .table tfoot tr {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table tfoot tr td {
    border: none;
    padding-top: rem(8px) !important;
    padding-bottom: 0 !important;
  }

  .table {
    //&.table-mobile {
    //  tr {
    //    padding: rem(12px);
    //    margin-bottom: rem(10px);
    //    display: grid;
    //    grid-template-areas:
    //    'name status'
    //    'dates status';
    //    grid-template-columns: 1fr;
    //    background-color: #F7F7F7 !important;
    //    border: 0;
    //  }
    //
    //  td {
    //    padding: 0 !important;
    //  }
    //}

    &-flex {
      tr {
        display: flex;
        flex-direction: column;
      }

      td {
        padding: rem(12px) 0;
        text-align: left !important;
      }
    }
  }


  .table--sm--no-thead {
    thead {
      display: none;
    }

    td {
      padding-left: rem(16px) !important;

      &:before {
        display: none;
      }
    }
  }

}


