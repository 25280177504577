.accordion {
  &__heading {
    font-weight: 500;
    padding: rem(25px) 0;
    cursor: pointer;

    .icon.upside-down {
      transform: scaleY(-1);
    }
  }

  hr {
    border-top: 1px solid $gray-300;
    margin: 0;
  }

  .card {
    margin-bottom: rem(12px);

    &-header {
      border: 0;
      position: relative;
      margin-bottom: 0 !important;
      padding: 0;

      .btn-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: bold;
        color: $main-site-color;
        font-size: rem(18px);
        text-decoration: none !important;
        padding: rem(22px) rem(48px);
        text-align: left;

        &:hover {
          text-decoration: none !important;
          //color: lighten($main-site-color, 10%);
          color: customLighten(--bg-scss-darken-lighten-color, 10);
        }

        @include media-breakpoint-only(md) {
          padding: rem(20px);
        }

        @include media-breakpoint-down(sm) {
          font-size: rem(16px);
          padding: rem(20px);
        }
      }

      &-icon {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: rem(12px);
        height: rem(2px);
        background-color: $main-site-color;
        margin-left: rem(15px);
        -webkit-transition: all 200ms ease-out;
        -moz-transition: all 200ms ease-out;
        -ms-transition: all 200ms ease-out;
        -o-transition: all 200ms ease-out;
        transition: all 200ms ease-out;

        &:before {
          content: '';
          display: flex;
          position: relative;
          top: 0;
          left: 0;
          width: rem(12px);
          height: rem(2px);
          background-color: $main-site-color;
          -moz-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          transform: rotate(90deg);
          -webkit-transition: all 200ms ease-out;
          -moz-transition: all 200ms ease-out;
          -ms-transition: all 200ms ease-out;
          -o-transition: all 200ms ease-out;
          transition: all 200ms ease-out;
        }
      }
    }

    &-body {
      background-color: #F7F7F7;
      padding: rem(8px) rem(48px) rem(16px);
      @include media-breakpoint-only(md) {
        padding: rem(20px);
      }

      @include media-breakpoint-down(sm) {
        font-size: rem(16px);
        padding: rem(20px);
      }
    }

  }

  .card-header a[aria-expanded="true"] {
    .card-header-icon {
      background: transparent;
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
}