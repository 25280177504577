﻿@import '../utils/mixins';
@import '../variables';
/*
-----------------------------------------
  3.00 Community
-----------------------------------------
*/

.community-board-item {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: rem(24px);

  &--muted {
    .community-board-item__left {
      background: rgba($white, 0.3);
      padding: 0;
    }

    .community-board-item-dropdown {
      .dropdown-toggle {
        background: transparent;
      }
    }

    .media-body--l,
    .community-board-item-user-image {
      opacity: 0.3;
    }
  }

  &--details {
    .community-board-item__left {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  &__left {
    position: relative;
    background: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-sm;
    padding: rem(12px) 0 rem(16px) 0;
    width: 70%;
    max-width: 70%;
    flex: 0 0 70%;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  &__right {
    position: relative;
    background: $gray-200;
    border-radius: $border-radius;
    margin-left: rem(24px);
    padding: rem(20px) rem(24px);
    width: calc(30% - #{rem(24px)});
    max-width: calc(30% - #{rem(24px)});
    flex: 0 0 calc(30% - #{rem(24px)});

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:after {
      display: block;
      content: '';
      width: rem(24px);
      height: rem(24px);
      background: $body-bg;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50% - #{rem(12px)});
      left: rem(-12px);
    }
  }
}

.community-board-item__header {
  padding: rem(12px) rem(24px);

  .media-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: calc(100% - #{rem(64px)});

    &--l {
      max-width: calc(100% - #{rem(52px)});
    }

    &--r {
      width: rem(52px);
      padding-left: rem(12px);
    }
  }

  .community-board-item-user-image {
    position: relative;
    display: block;
    margin-right: rem(16px);

    .badge {
      position: absolute;
      top: rem(-4px);
      right: rem(-4px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(20px);
      height: rem(20px);
      border-radius: 50%;
      background: $gray-200;
      font-size: rem(16px);

      &.thread-followed-by-user {
        background: $green;
        color: $white;
      }
    }
  }

  .community-board-item-title {
    font-size: $h4-font-size;
    font-weight: 500;
    line-height: 1.33;
    color: $gray-900;
    display: block;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(sm) {
      font-size: $h3-font-size;
      white-space: normal;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: rem(4px);
    }
  }

  .community-board-item-metadata {
    font-size: rem(14px);
    color: $gray-600;
    line-height: 1.4;
  }
}

.community-board-item__content {
  display: block;

  .video-preview {
    margin-bottom: 20px;
  }

  .community-board-item-message {
    padding: 0 rem(24px);
  }

  .community-board-item-tags {
    padding: 0 rem(24px);
  }

  .community-board-list-files {
    padding-bottom: rem(6px);

    @include media-breakpoint-down(sm) {
      padding-bottom: rem(14px);
    }
  }

  .community-board-item-reputation {
    padding: 0 rem(24px) rem(8px) rem(24px);
  }
}

.community-board-item__footer {
  border-top: 1px solid $hr-border-color;
  margin: 0 rem(24px);
  padding-top: rem(8px);

  .community-board-item-last-message {
    padding: rem(8px) 0 0 0;

    @include media-breakpoint-down(sm) {
      margin-top: rem(8px);
    }
  }

  .community-board-item-comment {
    padding: rem(12px) 0 rem(8px) 0;

    .nx-mentions-input-wrapper {
      padding: 0 rem(12px);

      .form-control {
        border: none;
        font-size: rem(16px);

        .form-control__control {
          font-family: $font-family-sans-serif !important;

          .form-control__highlighter,
          .form-control__input {
            padding: rem(4px) 0 !important;
            min-height: rem(32px) !important;
            font-size: rem(16px);
            @include placeholder(1, $gray-600, 400);
          }
        }
      }
    }
  }
}

.community-board-item__aside {
  display: block;
}

.community-board-list-files {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      border: 2px solid $white;
      min-width: 20%;
      max-width: 50%;
      flex: 1 1 auto;

      &:first-child,
      &:nth-child(6),
      &:nth-child(11),
      &:nth-child(16),
      &:nth-child(21),
      &:nth-child(26),
      &:nth-child(31),
      &:nth-child(36),
      &:nth-child(41),
      &:nth-child(46) {
        border-left: none;
      }

      &:last-child,
      &:nth-child(5),
      &:nth-child(10),
      &:nth-child(15),
      &:nth-child(20),
      &:nth-child(25),
      &:nth-child(30),
      &:nth-child(35),
      &:nth-child(40),
      &:nth-child(45),
      &:nth-child(50) {
        border-right: none;
      }

      .community-board-list-file {
        position: relative;
        cursor: pointer;
        @include transition();

        &--image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          padding-top: 72%;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($gray-900, 0.07);
            opacity: 0;
            @include transition(0.33s);
          }

          &:hover,
          &:active,
          &:focus {
            &:after {
              opacity: 1;
            }
          }
        }

        &--file {
          background-color: $gray-200;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 15%;
          padding-bottom: 15%;

          h6 {
          }

          i {
            font-size: rem(24px);
            margin-bottom: rem(16px);
          }

          small {
            font-size: rem(12px);
            color: $gray-600;
          }

          &:hover,
          &:active,
          &:focus {
            background-color: $gray-300;

            small {
              color: $gray-900;
            }
          }
        }

        .overlay {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($gray-900, 0.5);
          color: $white;
          font-size: rem(32px);
          font-weight: 500;
        }
      }
    }
  }

  &.array-legth-1 {
    ul li {
      max-width: 33%;
    }
  }
}

.community-board-reply {
  &__header {
    position: relative;

    .badge {
      position: absolute;
      top: rem(-4px);
      right: rem(-8px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(20px);
      height: rem(20px);
      border-radius: 50%;
      background: $bg-color;
      color: $text-color;

      i {
        font-size: rem(16px);
      }
    }
  }

  &__message {
    p {
      margin-bottom: rem(8px);
    }
  }

  &__options {
    white-space: nowrap;
    opacity: 0;
    @include transition();
  }

  &:hover {
    .community-board-reply__options {
      opacity: 1;
    }
  }
}
