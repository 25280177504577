﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  2.00 Header
-----------------------------------------
*/

.inside-iframe,
.in-ms-teams {
  .site-header {
    display: none !important;

    & ~ .content {
      margin-top: 0 !important;
    }
  }
}

//.site-mainmenu:not(.site-mainmenu--contracted) + {
//  .site-header--li {
//    .locations-menu {
//      .dropdown-toggle {
//        padding-left: 0 !important;
//      }
//    }
//  }
//}

//.site-mainmenu:not(.site-mainmenu--contracted) + .site-header.site-header--li {
//  .locations-menu .dropdown-toggle {
//    padding-left: 0 !important;
//  }
//}

.site-header {
  border-bottom: rem(1px) solid #EFEFEF;

  &--li {
    // Loged In
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    background-color: $white;

    .site-header__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: $site-member-header-height;
      //padding: 0 rem(24px);
      background: $white;

      .locations-menu {
        background-color: #EFEFEF !important;

        .dropdown-toggle {
          background-color: #EFEFEF !important;
          color: #23272b;
          font-weight: 400;

          i {
            color: $main-menu-item-icon-active;
          }
        }
      }

      &--left,
      &--right {
        display: flex;
        align-items: center;
      }

      &--right {
        .dropdown-toggle {
          color: $gray-900;
          font-weight: 400;
        }

        .caret {
          &:before {
            border-top-color: #777777;
          }
        }
      }

      .toggle-mobile {
        display: none;
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        font-size: rem(24px);
        line-height: 1;
        outline: none;
      }

      .brand {
        display: flex;
        font-size: rem(20px);
        font-weight: 700;
        text-decoration: none;
        color: $gray-900;
        line-height: 1;

        img {
          max-width: rem(196px);
          max-height: rem(50px);
        }
      }


      .locations-menu,
      .customer {
        position: relative;
        margin-left: rem(20px);

        &:before {
          display: block;
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: rem(2px);
          height: rem(24px);
          background: $gray-200;
          transform: translateY(-50%);
        }

        .dropdown-toggle {
          padding-left: $input-btn-padding-x;

          .text {
            font-size: rem(16px);
          }

          &:not(:hover):not(:active):not(:focus) {
            background: transparent;
          }
        }
      }

      .customer {
        margin-left: rem(12px);

        .dropdown-toggle {
          padding-left: rem(20px);
        }
      }
    }

    & ~ .content {
      margin-top: $site-member-header-height;
      padding: rem(40px) 0 rem(85px) 0;
    }
  }

  &--lo {
    // Loged Out
    position: sticky;
    top: 0;
    //right: 0;
    //left: 0;
    z-index: $zindex-fixed;

    .site-header__shadow {
      position: absolute;
      top: 100%;
      bottom: 0;
      left: 0;
      background: rgba($gray-900, 0.5);
      opacity: 1;
      height: 100vh;
      width: 100%;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .site-header__content {
      display: block;
      width: 100%;
      background: $white;

      .navbar {
        //padding: 0 rem(32px);
        //max-width: rem(1680px);
        margin: 0 auto;
        height: $site-client-header-height;
        padding: 0 $grid-gutter-width/2;

        @include media-breakpoint-down(lg) {
          padding: 0 rem(16px);
        }

        @include media-breakpoint-only(lg) {
          max-width: none;
        }

        i.icon-cart {
          color: $body-color;
        }

        .navbar-brand {
          padding-top: 0;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          margin-right: rem(32px);
          font-size: rem(16px);
          color: $gray-900;
          text-decoration: none;

          @include media-breakpoint-down(lg) {
            margin-right: rem(16px);
          }

          &:hover,
          &.active,
          &:focus {
            text-decoration: none;
          }

          img {
            height: rem(48px);
            width: rem(128px);
            object-fit: cover;
          }

          .spacer {
            display: inline-block;
            width: rem(2px);
            height: rem(24px);
            background-color: $gray-200;
            margin: 0 rem(20px);
          }
        }

        .navbar-toggler {
          outline: none !important;
          padding: rem(8px);

          i {
            font-size: rem(24px);
            color: $body-color;
          }

          &:hover,
          &:active,
          &:focus {
            outline: none !important;
          }

          @include media-breakpoint-down(md) {
          }
        }

        .navbar-collapse {
          @include media-breakpoint-down(md) {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: $zindex-popover;
            background: $white;
            padding: rem(16px);
            box-shadow: $box-shadow;
          }

          .navbar-nav {
            .nav-link {
              position: relative;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: none;
              background: transparent;
              outline: none !important;
              color: $gray-900;
              padding-left: 0;
              padding-right: 0;
              margin-left: rem(12px);
              margin-right: rem(12px);
              text-decoration: none !important;
              line-height: 1.2;
              white-space: nowrap;

              &:hover,
              &:focus,
              &:active {
                outline: none !important;
                text-decoration: underline !important;
                color: $link-color;

                & > .caret {
                  &:before {
                    border-top-color: $link-color;
                  }

                  &:after {
                    border-top-color: $white;
                  }
                }
              }

              & > .caret {
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                position: relative;
                width: rem(12px);
                height: rem(6px);
                margin-left: rem(8px);

                &:before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-top: 6px solid $gray-900;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                }

                &:after {
                  content: '';
                  position: absolute;
                  left: 2px;
                  top: 0;
                  border-top: 4px solid $white;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                }
              }
            }

            .dropdown-item {
              font-weight: 400;
              color: $gray-900;
              line-height: 1.2;

              &:hover,
              &:focus,
              &:active {
                background-color: transparent;
                color: $link-color;
              }
            }

            @include media-breakpoint-only(lg) {
              .nav-link,
              .dropdown-item {
                font-size: rem(14px);
              }

              .nav-link {
                margin-left: rem(8px);
                margin-right: rem(8px);
              }
            }

            @include media-breakpoint-down(md) {
              .nav-link,
              .dropdown-item {
                font-size: rem(18px);
              }

              .nav-link {
                margin: 0;
                width: 100%;
                justify-content: start;
                margin-bottom: 10px;
              }

              &.navbar-action {
                align-items: center;

                a {
                  color: var(--link-color);
                  font-size: 1.125rem;
                }

                .site-header-login-divider {
                  color: var(--link-color);
                }

                .nav-item:not(:last-child),
                .site-header-login-divider {
                  order: 2;
                }

                .nav-item:last-child {
                  order: 1;
                }
              }


              .dropdown-menu {
                box-shadow: none;
                margin: 0;

                .dropdown-item {
                  &:before {
                    display: inline-block;
                    content: '-';
                    padding: 0 rem(8px) 0 rem(16px);
                  }
                }
              }
            }
          }

          .navbar-action {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            @include media-breakpoint-down(md) {
              flex-direction: row;
              flex-wrap: wrap;
              align-items: flex-start;
              padding-top: rem(8px);
            }

            .spacer {
              display: inline-block;
              height: rem(24px);
              width: rem(2px);
              background: $gray-200;
              margin: 0 rem(20px) 0 rem(12px);

              @include media-breakpoint-only(lg) {
                margin: 0 rem(16px) 0 rem(8px);
              }

              @include media-breakpoint-down(md) {
                display: none;
              }
            }

            .btn-group {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }

    & ~ .content {

      &.content--custom {
        padding: rem(32px) 0 rem(64px) 0;
        min-height: calc(100vh - 440px);
        @include media-breakpoint-down(sm) {
          padding-bottom: rem(16px);
        }
      }
    }

    &.position-relative {
      & ~ .content {
        margin-top: 0;
      }
    }
  }

  &--with-bottom-content {
    .site-header__bottom-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 1.11;
      padding: 0 rem(32px);
      min-height: $site-sub-header-height;
      border-top: 2px solid $gray-100;
      background: lighten($red, 33%);
      color: $gray-900;

      @include media-breakpoint-down(md) {
        font-size: rem(12px);
      }

      .clear-notices {
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: rem(32px);
        font-size: rem(24px);
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
        outline: none;
        opacity: 0.75;
        @include transition();

        &:hover,
        &:focus,
        &:active {
          outline: none;
          opacity: 1;
        }
      }
    }

    & ~ .content {
      margin-top: $site-member-header-height + $site-sub-header-height !important;
      // height: calc(
      //   100vh - #{$site-member-header-height + $site-sub-header-height}
      // ) !important;
    }
  }

  .locations-menu {
    .dropdown-menu {
      max-height: rem(280px);
      overflow: auto;
      @include scrollbar();
    }
  }

  &-login {
    color: $gray-new-700;

    &-divider {
      color: $gray-new-700;
    }
  }
}

.nav-item-user-avatar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E4E4E4;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 17px;
    width: 15px;
    height: 15px;
    background-color: $white;
    border-radius: 5px 50px;
    transform: rotate(-45deg);
  }

  &:after {
    content: '';
    position: absolute;
    top: 6px;
    width: 10px;
    height: 10px;
    background-color: $white;
    border-radius: 50%;
  }
}

@include media-breakpoint-down(md) {
  .site-header--li {
    .site-header__content {
      padding: 0 rem(20px);
    }

    & ~ .content {
      padding: rem(20px) 0 rem(128px) 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .site-header--li {
    .site-header__content {
      .toggle-mobile {
        display: flex;
      }

      .brand {
        margin-left: rem(20px);
      }

      .locations-menu,
      .customer {
        display: none;
      }
    }
  }
}
