@import "../utils/mixins";
@import "../variables";

.modal-request-tour {
  .personal-details {
    .input-radio-box {
      flex: 0 1 15%;
      max-width: 15%;
    }
  }
}