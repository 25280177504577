﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 Navigation
-----------------------------------------
*/

.nav {
  &.nav-s8 {
    margin: rem(-4px);

    .nav-item {
      padding: rem(4px);
    }
  }

  &.nav-s4 {
    margin: rem(-2px);

    .nav-item {
      padding: rem(2px);
    }
  }

  &.nav-active-black {
    .btn {
      &.active {
        background: $gray-900 !important;
        color: $white !important;
      }
    }
  }
}

.nav-lateral {
  font-size: rem(14px);
  @include transition();

  @include media-breakpoint-down(md) {
    display: none;
  }

  a {
    color: $gray-600;
    font-weight: 500;
    text-decoration: none !important;

    &:active,
    &:focus {
      color: $gray-600;
    }

    &:hover {
      color: $gray-700;
    }

    &.active {
      color: $gray-900;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      margin: rem(6px) 0;
      color: $gray-600;
      font-weight: 500;

      &.active {
        color: $gray-900;
      }

      > a {
      }

      &.active {
        > a {
          color: $gray-900;
        }

        > ul {
          display: block;
        }
      }

      > ul {
        margin: 0 0 0 rem(12px);
        padding: 0;
        list-style-type: none;
        display: none;

        > li {
          margin: rem(6px) 0;
        }
      }
    }
  }

  &.affix {
  }

  &--mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.nav-lateral.lessons {
  li {
    margin-bottom: rem(12px);
  }

  ul {
    > li {
      > ul {
        display: block;
      }
    }
  }

  .list-item-collapsible {
    &:hover {
      cursor: pointer;
    }

    .icon.upside-down {
      transform: scaleY(-1);
    }
  }

  .lesson-link {
    .icon {
      color: $gray-900;
      height: 22px;

      &.yellow {
        background-color: #f8f0ce;
      }

      &.green {
        background-color: #d4f1df;
      }
    }
  }

  .locked {
    .lesson-title {
      margin-right: 4px;
    }

    .icon {
      color: $gray-900;
      vertical-align: middle;
      background-color: #fce4d8;
    }
  }
}
