﻿@mixin scrollbar($background: #fff, $width: 14px, $border: 4px, $color: rgba($gray-900, 0.1)) {
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    background-color: $background;
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-corner {
    background-color: $background;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $width;
    background-color: $color;
    border: $border solid $background;
  }
}

@mixin transition($time: 0.2s, $element: all, $animation: ease, $delay: 0s) {
  transition: $element $time $animation;
  transition-delay: $delay;
}

@mixin placeholder($opacity: 0.9, $color: $gray-500, $weight: 400) {
  &::-webkit-input-placeholder {
    font-weight: $weight;
    color: $color;
    opacity: $opacity;
  }

  &:-moz-placeholder {
    font-weight: $weight;
    color: $color;
    opacity: $opacity;
  }

  &::-moz-placeholder {
    font-weight: $weight;
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    font-weight: $weight;
    color: $color;
    opacity: $opacity;
  }
}

@function rscale($size: 0) { //Responsive scale
  @if (unitless($size)) {
    $size: $size * 1px;
  }

  @return calc(#{$size} * var(--scale));
}

/*#region Rem */

$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
  @if $separator == "comma" or $separator == "space" {
    @return append($list, null, $separator);
  }

  @if function-exists("list-separator") == true {
    @return list-separator($list);
  }

  $test-list: ();

  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);

  @each $value in $values {
    @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
      $result: append($result, $value / $rem-baseline * 1rem, $separator);
    } @else if type-of($value) == "list" {
      $value-separator: rem-separator($value);
      $value: rem-convert($to, $value...);
      $value: rem-separator($value, $value-separator);
      $result: append($result, $value, $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == "map" {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }

      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

/*#endregion */

@mixin center_transform() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@mixin vertical_center_transform() {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
}

// $value = %
@function customLighten($color-variable, $value) {
  $color-variable-h: var(#{$color-variable + "-h"});
  $color-variable-s: var(#{$color-variable + "-s"});
  $color-variable-l: calc(var(#{$color-variable + "-l"}) + #{$value});
  @return hsl($color-variable-h, $color-variable-s, $color-variable-l);
}

// $value = %
@function customDarken($color-variable, $value) {
  $color-variable-h: var(#{$color-variable + "-h"});
  $color-variable-s: var(#{$color-variable + "-s"});
  $color-variable-l: calc(var(#{$color-variable + "-l"}) - #{$value});
  @return hsl($color-variable-h, $color-variable-s, $color-variable-l);
}