﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  1.00 Pagination
-----------------------------------------
*/

.pagination {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  margin: 0 0 rem(18px) 0 !important;
  padding-bottom: rem(20px);
  padding-top: rem(20px);

  & > li {

    &.active {
      .btn {
        color: $bg-color;
        box-shadow: $box-shadow;
        cursor: default;

        &:hover, &:active, &:focus {
          color: $bg-color;
          box-shadow: $box-shadow;
        }
      }
    }
  }

  .btn {
    padding: 0;
    min-width: $input-btn-height-xs;
    min-height: $input-btn-height-xs;
    text-align: center;
    justify-content: center;
    background: transparent;
    color: $body-color;
    box-shadow: none;
    border: 0 !important;
    border-radius: 0 !important;

    &.prev, &.next {
      color: $body-color;

      i {
        display: block;
        font-size: $input-btn-icon-size;
      }

      &:hover, &:active, &:focus {
        color: $bg-color;
      }
    }

    &.prev {
      i {
        transform: rotate(180deg);
      }
    }

    &.dots {
      cursor: default;

      &:hover, &:active, &:focus {
        background: transparent;
        color: $gray-600;
        box-shadow: none;
      }
    }

    &:hover, &:active, &:focus {
      color: $bg-color;
    }
  }
}
