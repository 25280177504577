﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 Buttons
-----------------------------------------
*/

.avatar {
  display: inline-block;
  width: rem(32px);
  height: rem(32px);
  border-radius: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  //background-color: $gray-200;

  &--16 {
    width: rem(16px);
    height: rem(16px); 
  }

  &--40 {
    width: rem(40px);
    height: rem(40px);
  }

  &--48 {
    width: rem(48px);
    height: rem(48px);
  }

  &--100 {
    width: rem(100px);
    height: rem(100px);
  }

  &--140 {
    width: rem(140px);
    height: rem(140px);
  }

  &--160 {
    width: rem(160px);
    height: rem(160px);
  }

  &--border-2-s-200 {
    border: 2px solid $gray-200;
  }

  &--border-2-s-white {
    border: 2px solid $white;
  }
}
