﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 General
-----------------------------------------
*/

.border-dashed {
  border: 1px dashed $hr-border-color !important;
}

.cp {
  cursor: pointer;
}

.mh-32 {
  min-height: rem(32px) !important;
}

.mh-50vh {
  min-height: 50vh;
}

.hr-1 {
  border-top-width: 1px;
}

.w-md-20 {
  @include media-breakpoint-up(md) {
    width: 20% !important;
  }
}

.w-md-25 {
  @include media-breakpoint-up(md) {
    width: 25% !important;
  }
}

.w-md-50 {
  @include media-breakpoint-up(md) {
    width: 50% !important;
  }
}

.w-md-60 {
  @include media-breakpoint-up(md) {
    width: 60% !important;
  }
}

.w-lg-50 {
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.w-sm-100 {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.mxw-xl-50 {
  @include media-breakpoint-up(xl) {
    max-width: 50%;
  }
}

.mw-sm-64px {
  @include media-breakpoint-down(sm) {
    min-width: rem(64px) !important;
  }
}

.mw-sm-72px {
  @include media-breakpoint-down(sm) {
    min-width: rem(72px) !important;
  }
}

.mw-250px {
  min-width: rem(250px) !important;
}

.mxw-400px {
  max-width: rem(400px) !important;
}

.mxw-500px {
  max-width: rem(500px) !important;
}

.mxw-540px {
  max-width: rscale(540px) !important;
}

.mxw-600px {
  max-width: rem(600px) !important;
}

.mw-640 {
  max-width: rscale(640px);

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.mw-720 {
  max-width: rscale(720px);

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.mw-820 {
  max-width: rscale(820px);

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.mw-840 {
  max-width: rscale(840px);
  margin: 0 auto;
}

/* FROALA FIX */
.fr-view ul,
.fr-view ol {
  list-style-position: outside !important;
}
