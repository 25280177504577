﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  3.00 Settings
-----------------------------------------
*/

.hide-main-menu {
  .settings {
    .col-lg-3 {
      display: none !important;
    }
  }
}

.in-ms-teams {
  #calendar-sync-section {
    display: none;
  }
}

.settings {
  &__nav {
    position: sticky;
    top: 0;
  }

  &__content {
    padding-bottom: rem(48px);

    fieldset {
      position: relative;
      display: block;

      &:not(:last-child) {
        padding: 0 0 rem(24px) 0;
        margin: 0 0 rem(32px) 0;
        border-bottom: 1px solid $hr-border-color;
      }

      .section__title {
        position: relative;
        display: block;
        font-weight: 700;
        font-size: rem(18px);
        line-height: 1.33;
        margin-bottom: rem(20px);
      }

      small {
        display: block;
        font-size: rem(12px);
        line-height: 1.3;
        color: $gray-600;
        margin: 0;
        margin-top: rem(8px);
      }
    }

    .table {
      .tag {
        display: inline-flex;

        &.red {
          background-color: #f8d7da;
        }

        &.yellow {
          background-color: #f8f0ce;
        }

        &.green {
          background-color: #d4f1df;
        }
      }
    }

    .team-members-permissions {
      .table {
        table-layout: fixed;

        thead {
          tr {
            @include media-breakpoint-down(md) {
              position: absolute;
              top: -9999px;
              left: -9999px;
              padding-top: rem(16px);
              padding-bottom: rem(16px);
            }

            th {
              width: 80px;

              &.name-col {
                width: 100px;
              }

              &.access-card-col {
                width: 130px;
              }

              &.access-col {
                width: 50px;
              }

              @include media-breakpoint-up(xl) {
                width: 20px;

                &.name-col {
                  width: 100px;
                }

                &.access-card-col {
                  width: 70px;
                }

                &.access-col {
                  width: 40px;
                }
              }
            }
          }
        }

        tbody {
          tr {
            @include media-breakpoint-down(md) {
              padding-top: rem(16px);
              padding-bottom: rem(16px);
            }
          }
        }

        tr,
        td {
          @include media-breakpoint-down(md) {
            display: block;
          }
        }

        td {
          @include media-breakpoint-down(md) {
            position: relative;
            padding-left: 35% !important;
            white-space: normal;
            text-align: right;

            div {
              justify-content: flex-end;
            }

            &:before {
              content: attr(data-title);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 15px;
              width: 45%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              font-weight: 500;
            }
          }

          &.access-cell {
            @include media-breakpoint-down(lg) {
              padding: 0;
            }

            @include media-breakpoint-down(md) {
              padding: rem(10px) rem(16px);
            }
          }

          .avatar,
          .access-text {
            @include media-breakpoint-down(lg) {
              display: none;
            }

            @include media-breakpoint-down(md) {
              display: inline;
            }
          }

          .icon {
            @include media-breakpoint-down(lg) {
              color: $gray-600;
            }

            @include media-breakpoint-down(md) {
              color: $gray-900;
            }
          }
        }

        .react-tag-input__input {
          overflow: hidden;
        }

        .access {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}

#notifications-settings {
  table {
    margin-bottom: 0 !important;
  }

  tr {
    border: 0;
    padding: 0 !important;

    &:not(:last-child) {
      td {
        margin-bottom: rem(20px) !important;
        @include media-breakpoint-down(sm) {
          margin-bottom: 0 !important;
          border-bottom: rem(1px) solid #efefef;
        }
      }
    }
  }

  td {
    background-color: #F7F7F7 !important;
    padding: rem(16px) rem(24px);
    //display: block !important;
  }
}

.credit-card {
  &-information {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: rem(-24px);
    margin-left: rem(-24px);
    @include media-breakpoint-only(md) {
      justify-content: center;
    }

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }

  &-item {
    position: relative;
    width: 13em;
    max-width: 13em;
    max-height: 7.7em;
    height: 7.7em;
    color: #E0E0E0;

    &-main {
      font-size: 1.35vw;
      padding-right: rem(24px);
      padding-left: rem(24px);

      @include media-breakpoint-only(md) {
        font-size: 2.25vw;
      }

      @include media-breakpoint-down(sm) {
        font-size: rem(18px);
      }
    }

    &-front {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: inherit;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-font-smoothing: antialiased;
      border-radius: 0.65em;
      overflow: hidden;
      padding: 0.77em 0.77em 0.77em 0.65em;
    }

    &-cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(251.59deg, #726E9E -1.95%, #042843 103.4%);
    }

    &-line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzM5IiBoZWlnaHQ9IjE3NiIgdmlld0JveD0iMCAwIDMzOSAxNzYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNzguODU5IDg3LjIzMTRDOTUuNjY4NyA4Ny4yMzE0IDI0Ljk1NzEgNDMuODczOCAwIDIyLjE5NVYwSDMzOVYxNzUuNDk1QzMyMC4yODIgMTQ2LjA3NCAyNjIuMDQ5IDg3LjIzMTQgMTc4Ljg1OSA4Ny4yMzE0WiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzc2NF8xNzMyMikiIHN0eWxlPSJtaXgtYmxlbmQtbW9kZTpvdmVybGF5Ii8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfNzY0XzE3MzIyIiB4MT0iMjc5LjIwNyIgeTE9Ii0zOC4xOTYiIHgyPSIyMjUuODUxIiB5Mj0iMTM5LjA2NCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjMTQzMzUxIi8+CjxzdG9wIG9mZnNldD0iMC45MDE0OTMiIHN0b3AtY29sb3I9IiMxNDMzNTEiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=");
      background-size: cover;
      background-repeat: no-repeat;
    }

    &-wrapper {
      position: relative;
    }

    &-type {
      margin-bottom: 1.42em;
      text-align: right;
      font-size: 0.61em;
      font-weight: 700;
    }

    &-chip {
      margin-bottom: 1em;

      img {
        width: 1.7em;
        height: 1.07em;
      }
    }

    &-content {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    &-holder {
      font-size: 0.46em;
      color: #F2F2F2;
      font-weight: 400;
      margin-bottom: 0.2em;
    }

    &-number {
      font-weight: 700;
      font-size: 0.57em;
    }

    &-date {
      font-size: 0.57em;
    }

    &-form {
      width: 100%;
      max-width: rem(480px);
      padding-right: rem(24px);
      padding-left: rem(24px);

      @include media-breakpoint-down(sm) {

      }


    }
  }
}