﻿@import '../utils/mixins';
@import '../variables';
/*
-----------------------------------------
  3.00 Directory
-----------------------------------------
*/

.directory-profile-page-header,
.directory-team-profile-page-header {
  background: $white;
  border-radius: $border-radius;
  margin-bottom: rem(16px);
  box-shadow: $box-shadow-sm;

  &__images {
    position: relative;
    background-color: $gray-300;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $border-radius;
    height: rem(268px);

    .avatar {
      position: absolute;
      bottom: rem(-80px);
      left: rem(24px);
      border: rem(4px) solid $white;
      background-color: $white;
      box-shadow: $box-shadow;

      @include media-breakpoint-down(sm) {
        bottom: rem(24px);
        left: rem(24px);
      }
    }

    .badge {
      display: flex;
      align-items: center;
      position: absolute;
      top: rem(18px);
      left: rem(18px);
      font-weight: 500;
      font-size: rem(12px);
      background: $gray-200;
      color: $gray-900;
      border-radius: rem(4px);

      i {
        font-size: rem(16px);
        margin-right: rem(4px);
      }

      &.right {
        left: auto;
        right: rem(18px);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding: rem(20px) rem(24px) rem(32px) rem(204px);

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: rem(24px);
    }

    @include media-breakpoint-down(sm) {
      padding: rem(24px);
    }

    &--l {
      h2 {
        margin-bottom: rem(4px);
      }

      p {
        margin-bottom: rem(4px);
        color: $gray-700;
      }

      .list-tags {
        margin-top: rem(24px);

        @include media-breakpoint-down(md) {
          margin-top: rem(8px);
        }

        li {
          a {
            text-transform: capitalize;
            color: $gray-900;
            font-weight: 500;
          }
        }
      }
    }

    &--r {
      padding-top: rem(4px);

      @include media-breakpoint-down(md) {
        padding-top: rem(24px);
      }
    }
  }
}

.directory-profile-page-body,
.directory-team-profile-page-body {
  .about {
    padding: rem(32px);
    padding-bottom: rem(16px);

    @include media-breakpoint-down(sm) {
      padding: rem(24px);
      padding-bottom: rem(8px);
    }

    &:not(:last-child) {
      margin-bottom: rem(10px);
    }

    h1,
    h2,
    h3,
    h4 {
      font-size: 1.1rem;
    }
  }
}

.directory-profile-page-side,
.directory-team-profile-page-side {
  .location {
    padding: rem(20px) rem(24px);
    margin-bottom: rem(16px);
  }

  .connect {
    padding: rem(20px) rem(24px) rem(8px) rem(24px);
    margin-bottom: rem(16px);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: rem(12px) 0;

        a {
          display: inline-flex;
          align-items: center;
          text-decoration: none !important;
          color: $gray-900;

          i {
            font-size: rem(24px);
            width: 32px;
            color: $gray-600;
            @include transition();

            &.fa {
              font-size: rem(20px);
              padding-left: rem(3px);
            }
          }

          span {
            text-decoration: underline;
            text-decoration-color: transparent;
            @include transition();
          }

          &:hover,
          &:active,
          &.focus {
            text-decoration: none !important;

            i {
              text-decoration: none;
              color: $gray-900;
            }

            span {
              text-decoration-color: currentColor;
            }
          }
        }
      }
    }
  }

  .similar-profiles {
    padding: rem(20px) rem(24px) rem(8px) rem(24px);
    margin-bottom: rem(16px);
  }

  .twitter {
    padding: rem(20px) rem(24px);
  }
}
