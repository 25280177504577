@font-face {
  font-family: 'nexudus-icons-desktop';
  src:  url('fonts/nexudus-icons-desktop.eot?mkkq22');
  src:  url('fonts/nexudus-icons-desktop.eot?mkkq22#iefix') format('embedded-opentype'),
    url('fonts/nexudus-icons-desktop.ttf?mkkq22') format('truetype'),
    url('fonts/nexudus-icons-desktop.woff?mkkq22') format('woff'),
    url('fonts/nexudus-icons-desktop.svg?mkkq22#nexudus-icons-desktop') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nexudus-icons-desktop' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-card-view:before {
  content: "\e91e";
}
.icon-icon-clock:before {
  content: "\e9b8";
  color: #4b4b4b;
}
.icon-icon-help:before {
  content: "\e9ba";
}
.icon-icon-copy:before {
  content: "\e904";
}
.icon-icon-calendar:before {
  content: "\e9ac";
}
.icon-icon-card-view:before {
  content: "\e9ad";
}
.icon-icon-chevron-right:before {
  content: "\e9ae";
}
.icon-icon-pen:before {
  content: "\e9b0";
}
.icon-icon-support:before {
  content: "\e9b1";
}
.icon-downloads:before {
  content: "\e9be";
}
.icon-i-arrow-i:before {
  content: "\e9c5";
}
.icon-exit-new:before {
  content: "\e9c4";
}
.icon-my-details:before {
  content: "\e9c3";
}
.icon-home:before {
  content: "\e9c1";
}
.icon-sorting:before {
  content: "\e9c2";
}
.icon-alert-2:before {
  content: "\e9bf";
}
.icon-confetti-c:before {
  content: "\e9bc";
}
.icon-icon-arrow-left:before {
  content: "\e9bb";
}
.icon-Combined-Shape:before {
  content: "\e9b9";
}
.icon-icon-internet:before {
  content: "\e9b2";
}
.icon-icon-large-display:before {
  content: "\e9b3";
}
.icon-icon-natura-light:before {
  content: "\e9b4";
}
.icon-icon-privacy-screen:before {
  content: "\e9b5";
}
.icon-icon-security-lock:before {
  content: "\e9b6";
}
.icon-icon-tea-coffee:before {
  content: "\e9b7";
}
.icon-icon-map:before {
  content: "\e9af";
}
.icon-icon-dashboard:before {
  content: "\e900";
}
.icon-icon-list:before {
  content: "\e901";
}
.icon-icon-meetings:before {
  content: "\e902";
}
.icon-icon-settings:before {
  content: "\e903";
}
.icon-add:before {
  content: "\e905";
}
.icon-whiteboard-2:before {
  content: "\e906";
}
.icon-graduation:before {
  content: "\e907";
}
.icon-privacy-shield:before {
  content: "\e908";
}
.icon-added-services:before {
  content: "\e909";
}
.icon-add-photo:before {
  content: "\e90a";
}
.icon-add-small:before {
  content: "\e90b";
}
.icon-alert:before {
  content: "\e90c";
}
.icon-android:before {
  content: "\e90d";
}
.icon-android-square:before {
  content: "\e90e";
}
.icon-apple:before {
  content: "\e90f";
}
.icon-apple-square:before {
  content: "\e910";
}
.icon-arrow-bend-down:before {
  content: "\e911";
}
.icon-arrow-right:before {
  content: "\e912";
}
.icon-arrow-right-small:before {
  content: "\e913";
}
.icon-ask-for-help:before {
  content: "\e914";
}
.icon-attendee-add:before {
  content: "\e915";
}
.icon-blogger:before {
  content: "\e916";
}
.icon-book-edit:before {
  content: "\e917";
}
.icon-bookings:before {
  content: "\e918";
}
.icon-bookmark-tabs:before {
  content: "\e919";
}
.icon-bookmark-tabs-unfollow:before {
  content: "\e91a";
}
.icon-business-spaces:before {
  content: "\e91b";
}
.icon-calendar-small:before {
  content: "\e91c";
}
.icon-calendar-view:before {
  content: "\e91d";
}
.icon-cart:before {
  content: "\e91f";
}
.icon-cart-empty:before {
  content: "\e920";
}
.icon-catering:before {
  content: "\e921";
}
.icon-cctv:before {
  content: "\e922";
}
.icon-check:before {
  content: "\e923";
}
.icon-chevron-right:before {
  content: "\e924";
}
.icon-childcare:before {
  content: "\e925";
}
.icon-claim-discount:before {
  content: "\e926";
}
.icon-clap:before {
  content: "\e927";
}
.icon-climate:before {
  content: "\e928";
}
.icon-close:before {
  content: "\e929";
}
.icon-close-large:before {
  content: "\e92a";
}
.icon-close-sidebar:before {
  content: "\e92b";
}
.icon-code:before {
  content: "\e92c";
}
.icon-coffee-and-tea:before {
  content: "\e92d";
}
.icon-comment:before {
  content: "\e92e";
}
.icon-community:before {
  content: "\e92f";
}
.icon-conference-call:before {
  content: "\e930";
}
.icon-conference-phone:before {
  content: "\e931";
}
.icon-confimred:before {
  content: "\e932";
}
.icon-credit-card:before {
  content: "\e933";
}
.icon-dashboard:before {
  content: "\e934";
}
.icon-date-availability:before {
  content: "\e935";
}
.icon-delete:before {
  content: "\e936";
}
.icon-desks:before {
  content: "\e937";
}
.icon-disconnect:before {
  content: "\e938";
}
.icon-discount:before {
  content: "\e939";
}
.icon-document:before {
  content: "\e93a";
}
.icon-dollar:before {
  content: "\e93b";
}
.icon-download:before {
  content: "\e93c";
}
.icon-drinks:before {
  content: "\e93d";
}
.icon-dropbox-square:before {
  content: "\e93e";
}
.icon-dropdown:before {
  content: "\e93f";
}
.icon-dropdown-large:before {
  content: "\e940";
}
.icon-dropdown-tiny:before {
  content: "\e941";
}
.icon-edit:before {
  content: "\e942";
}
.icon-email:before {
  content: "\e943";
}
.icon-email-delivery:before {
  content: "\e944";
}
.icon-email-square:before {
  content: "\e945";
}
.icon-expand-sidebar:before {
  content: "\e946";
}
.icon-external-link:before {
  content: "\e947";
}
.icon-featured-article:before {
  content: "\e948";
}
.icon-filter:before {
  content: "\e949";
}
.icon-flickr:before {
  content: "\e94a";
}
.icon-floorplan-view:before {
  content: "\e94b";
}
.icon-github:before {
  content: "\e94c";
}
.icon-go-back:before {
  content: "\e94d";
}
.icon-google-square:before {
  content: "\e94e";
}
.icon-heating:before {
  content: "\e94f";
}
.icon-home-square:before {
  content: "\e950";
}
.icon-inbox:before {
  content: "\e951";
}
.icon-instant-delivery:before {
  content: "\e952";
}
.icon-intercom:before {
  content: "\e953";
}
.icon-invoice:before {
  content: "\e954";
}
.icon-large-display:before {
  content: "\e955";
}
.icon-likes:before {
  content: "\e956";
}
.icon-link:before {
  content: "\e957";
}
.icon-location:before {
  content: "\e958";
}
.icon-lock:before {
  content: "\e959";
}
.icon-log-out:before {
  content: "\e95a";
}
.icon-map-square:before {
  content: "\e95b";
}
.icon-marker-pin:before {
  content: "\e95c";
}
.icon-marker-pin-circle:before {
  content: "\e95d";
}
.icon-medium:before {
  content: "\e95e";
}
.icon-meeting-room:before {
  content: "\e95f";
}
.icon-membership:before {
  content: "\e960";
}
.icon-menu:before {
  content: "\e961";
}
.icon-minus:before {
  content: "\e962";
}
.icon-money:before {
  content: "\e963";
}
.icon-more:before {
  content: "\e964";
}
.icon-my-bookings-end-date:before {
  content: "\e965";
}
.icon-my-bookings-start-date:before {
  content: "\e966";
}
.icon-my-discussion-board:before {
  content: "\e967";
}
.icon-my-space:before {
  content: "\e968";
}
.icon-natural-light:before {
  content: "\e969";
}
.icon-navigation:before {
  content: "\e96a";
}
.icon-network:before {
  content: "\e96b";
}
.icon-next-date:before {
  content: "\e96c";
}
.icon-note:before {
  content: "\e96d";
}
.icon-notifications:before {
  content: "\e96e";
}
.icon-paypal:before {
  content: "\e96f";
}
.icon-pending:before {
  content: "\e970";
}
.icon-perk-benefits:before {
  content: "\e971";
}
.icon-phone:before {
  content: "\e972";
}
.icon-phone-square:before {
  content: "\e973";
}
.icon-pin-location-sqaure:before {
  content: "\e974";
}
.icon-pinterest:before {
  content: "\e975";
}
.icon-privacy-screen:before {
  content: "\e976";
}
.icon-private:before {
  content: "\e977";
}
.icon-products:before {
  content: "\e978";
}
.icon-projector:before {
  content: "\e979";
}
.icon-quiet-zone:before {
  content: "\e97a";
}
.icon-remove:before {
  content: "\e97b";
}
.icon-replies:before {
  content: "\e97c";
}
.icon-reply-received:before {
  content: "\e97d";
}
.icon-request-date:before {
  content: "\e97e";
}
.icon-request-sent:before {
  content: "\e97f";
}
.icon-safe-box:before {
  content: "\e980";
}
.icon-search:before {
  content: "\e981";
}
.icon-send:before {
  content: "\e982";
}
.icon-settings:before {
  content: "\e983";
}
.icon-simple-pay:before {
  content: "\e984";
}
.icon-skype:before {
  content: "\e985";
}
.icon-slack:before {
  content: "\e986";
}
.icon-social-facebook:before {
  content: "\e987";
}
.icon-social-instagram:before {
  content: "\e988";
}
.icon-social-linkedin:before {
  content: "\e989";
}
.icon-social-tumblr:before {
  content: "\e98a";
}
.icon-social-twitter:before {
  content: "\e98b";
}
.icon-soundproof:before {
  content: "\e98c";
}
.icon-standing-desk:before {
  content: "\e98d";
}
.icon-start-conversation:before {
  content: "\e98e";
}
.icon-starting-time:before {
  content: "\e98f";
}
.icon-support:before {
  content: "\e990";
}
.icon-tea-and-coffee:before {
  content: "\e991";
}
.icon-text-bold:before {
  content: "\e992";
}
.icon-text-bullet-list:before {
  content: "\e993";
}
.icon-text-check-list:before {
  content: "\e994";
}
.icon-text-italic-text:before {
  content: "\e995";
}
.icon-text-number-list:before {
  content: "\e996";
}
.icon-text-quote:before {
  content: "\e997";
}
.icon-text-strike:before {
  content: "\e998";
}
.icon-text-text-size:before {
  content: "\e999";
}
.icon-ticket:before {
  content: "\e99a";
}
.icon-time:before {
  content: "\e99b";
}
.icon-top-facilites:before {
  content: "\e99c";
}
.icon-tumblr:before {
  content: "\e99d";
}
.icon-unlike:before {
  content: "\e99e";
}
.icon-unpaid-invoices:before {
  content: "\e99f";
}
.icon-user:before {
  content: "\e9a0";
}
.icon-user-circle:before {
  content: "\e9a1";
}
.icon-video-call:before {
  content: "\e9a2";
}
.icon-vimeo:before {
  content: "\e9a3";
}
.icon-voice-recorder:before {
  content: "\e9a4";
}
.icon-website-url:before {
  content: "\e9a5";
}
.icon-whiteboard:before {
  content: "\e9a6";
}
.icon-wifi:before {
  content: "\e9a7";
}
.icon-wireless-charger:before {
  content: "\e9a8";
}
.icon-world-square:before {
  content: "\e9a9";
}
.icon-youtube-square:before {
  content: "\e9aa";
}
.icon-zoom:before {
  content: "\e9ab";
}
