﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  1.00 Tabs
-----------------------------------------
*/

.nav-tabs {
  border: none;
  margin: rem(-4px);
  padding: 0;

  .nav-item {
    margin: 0;
    padding: rem(4px);

    .nav-link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: $input-btn-height;
      padding: $input-btn-padding-y $input-btn-padding-x;
      font-size: $input-btn-font-size;
      font-weight: $btn-font-weight;
      border-radius: $btn-border-radius;
      line-height: $btn-line-height;
      transition: $btn-transition;
      white-space: nowrap;
      overflow: hidden;
      min-width: 0;
      max-width: 100%;
      text-align: center;
      text-overflow: ellipsis;
      text-decoration: none;
      box-shadow: none;
      border: none !important;
      background: $gray-100;
      color: $gray-600;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none !important;
        border: none !important;
        background: $gray-300;
        color: $gray-900;
      }

      &.active {
        background: $gray-900 !important;
        color: $white !important;
      }
    }
  }

  &-sm {
    margin: rem(-2px);

    .nav-item {
      padding: rem(2px);

      .nav-link {
        height: $input-btn-height-sm;
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
        font-size: $input-btn-font-size-sm;
      }
    }
  }
}

.tab-content {
}
