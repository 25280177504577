.funnel-steps {

  &-wrapper {
    margin-bottom: rem(24px);

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-bottom: rem(24px);
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $card-item-header-y $card-item-header-x;
    border-bottom: 1px solid $gray-new;
    background-color: $white;

    &-title {
      font-size: rem(16px);
      font-weight: 500;
      margin: rem(10px) 0;
      color: #000;
    }

    &-login {
      @media only screen and (max-width: 480px) {
        width: 100% !important;
      }
    }

    @include media-breakpoint-down(md) {
      padding: $card-item-header-md-y $card-item-header-md-x;
    }

    @include media-breakpoint-down(sm) {
      padding: $card-item-header-sm-y $card-item-header-sm-x;
    }
  }

  &-body {
    padding: rem(36px);
    background-color: $white;

    [data-component-name="SignupForm"] {
      max-width: none;
    }

    .card-dashboard {
      padding-left: 0;
      padding-right: 0;
    }

    h5 {
      font-size: rem(16px);
      font-weight: 400;
    }

    @include media-breakpoint-down(md) {
      padding: rem(20px);
    }

    @include media-breakpoint-down(sm) {
      padding: rem(20px);
    }

    .card-item-body {
      padding: 0;
    }

    .card-item-header {
      padding: 1rem 0;
      border: 0;
    }

    .meetings-list {
      padding: 0;
    }
  }

  &-price-total {
    font-size: rem(20px);
    color: var(--bg-color);
    text-align: right;

    &-title {
      font-size: rem(18px);
    }
  }

  &-advance-payment-information {
    font-size: rem(14px);
    color: #777777;
  }
}

.card-item-header {

  @media only screen and (max-width: 480px) {
    .btn,
    .btn-group {
      width: 100%;
    }
  }
}

.step-buttons {
  position: sticky;
  bottom: 0;
  background-color: $white;
  padding: rem(20px) 0;
  border-top: 1px solid $gray-new;
  margin-top: rem(20px);
  z-index: 10;

  .product-totals {
    padding: 0;
    border: 0;
  }

  .btn {
    min-width: rem(189px);
    @include media-breakpoint-down(sm) {
      min-width: rem(120px);
    }

    @media only screen and (max-width: 576px) {
      min-width: auto;
      width: 100%;
    }
  }

  .product-totals {
    margin-top: 0;

    @media only screen and (max-width: 576px) {
      width: 100%;
      & > div {
        flex-direction: column;
      }
    }
  }

}

@media only screen and (min-width: 768px) {
  .site-mainmenu ~ .step-buttons {
    margin-left: 4.5rem;
  }

  .site-mainmenu--docked ~ .step-buttons {
    margin-left: 17.5rem;
  }
}

.plan-gallery {
  --gap: 16px;
  --num-cols: 3;
  --row-height: 180px;

  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      width: 80%;
    }
  }

  & > a {
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-transition: all .5s ease;
      -o-transition: all .5s ease;
      transition: all .5s ease;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        transform: scale(1.1);
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      min-width: 80%;
    }
  }

  .image-grid-col-2 {
    grid-column: span 2;
  }

  .image-grid-row-2 {
    grid-row: span 2
  }

  @include media-breakpoint-down(md) {
    --num-cols: 2;
    --row-height: 110px;
    --gap: 8px;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    overflow: auto;
    overflow-y: hidden;
    height: rem(250px);
  }

}

@include media-breakpoint-down(sm) {
  .plan-title-mobile {
    padding-bottom: rem(10px);
    border-bottom: 1px solid #EFEFEF;
  }
}