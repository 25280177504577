﻿@import '../utils/mixins';
@import '../variables';
/*
-----------------------------------------
  3.00 Bookings
-----------------------------------------
*/

.bookings-available-slots {
  display: flex;
  flex-wrap: nowrap;
  height: rem(40px);
  padding: rem(4px) 0;

  .slot {
    position: relative;

    flex-grow: 1;
    min-width: rem(4px);
    margin-left: rem(1px);
    margin-top: rem(6px);
    margin-bottom: rem(6px);

    &.available {
      cursor: pointer;
      background: $gray-200;

      &:hover {
        background: darken($gray-200, 5%);
      }
    }

    &.not-available,
    &.booked {
      background: $red !important;

      &:hover {
        background: $red !important;
      }
    }

    &.selected {
      background: $gray-400;

      &:hover {
        background: $gray-400;
      }

      &:before {
        content: '';
        position: absolute;
        top: rem(-6px);
        left: rem(-1px);
        right: 0;
        bottom: rem(-6px);
        border-top: 2px solid $green;
        border-bottom: 2px solid $green;
        z-index: 1;
      }

      &--from {
        &:before {
          left: rem(-6px);
          border-left: 2px solid $green;
          border-top-left-radius: rem(4px);
          border-bottom-left-radius: rem(4px);
        }
      }

      &--to {
        &:before {
          right: rem(-6px);
          border-right: 2px solid $green;
          border-top-right-radius: rem(4px);
          border-bottom-right-radius: rem(4px);
        }
      }
    }
  }
}

.bookings-map-view {
  .map-container {
    position: relative;

    .geosuggest {
      width: 100%;

      .geosuggest__input-wrapper {
        width: 100%;
      }

      .geosuggest__suggests-wrapper {
        z-index: 999999;
      }

      .dropdown-menu {
        position: absolute;
        top: 30px;
        left: 0;

        &.geosuggest__suggests--hidden {
          display: none;
        }
      }

      .geosuggest__item {
        padding: 11px 16px;
        cursor: pointer;

        &--active,
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
    .map-no-results {
      width: 100%;
      position: absolute;
      bottom: rem(40px);
      padding-top: rem(20px);
      white-space: nowrap;
      overflow: hidden;
      text-align: center;

      div {
        background-color: white;
        margin: 30px;
        padding: 20px;
        border-radius: var(--border-radius);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
      }
    }

    .resources-cards {
      width: 100%;
      position: absolute;
      bottom: rem(40px);
      padding-top: rem(20px);
      white-space: nowrap;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        bottom: rem(20px);
        overflow-x: scroll;
      }
    }

    .see-more {
      background-color: $white;
      color: $gray-800;
      position: sticky;
      height: 46px;
      width: 46px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      padding: rem(20px);
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);

      &:active {
        background-color: $bg-color;
      }

      &.right {
        right: rem(40px);
      }

      &.left {
        right: calc(100% - 80px);

        i {
          transform: scaleX(-1);
        }
      }
    }
  }

  .resource-marker {
    transform: scale(1);
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.2);
      transition: transform 0.2s;
    }
  }
}
