﻿@import "../utils/mixins";
@import "../variables";

/*

-----------------------------------------
  3.00 Dashboard
-----------------------------------------

*/


.dashboard-bookings-widget {

  & > div:nth-child(3) {
    @include media-breakpoint-down(lg) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}

.dashboard-events-widget {
  .card-event {
    margin-top: rem(16px);
  }
}

.dashboard-community-board-widget {
  .card-community-board {
    margin-top: rem(16px);
  }
}

.virtual-feed-widget {

  fieldset {
    position: relative;
    display: block;

    &:not(:last-child) {
      margin: 0 0 rem(32px) 0;
      border-bottom: 1px solid $hr-border-color;
    }

    .section__title {
      position: relative;
      display: block;
      font-weight: 700;
      font-size: $h5-font-size;
      line-height: 1.33;
      margin-bottom: rem(20px);
    }
  }

  .nx-mentions-input-wrapper {
    padding: 0 rem(12px);

    .form-control {
      border: none;
      font-size: rem(16px);

      .form-control__control {
        font-family: $font-family-sans-serif !important;

        .form-control__highlighter, .form-control__input {
          padding: rem(4px) 0 !important;
          min-height: rem(32px) !important;
          font-size: rem(16px);
          @include placeholder(1, $gray-600, 400);
        }
      }
    }
  }

  .card-event--md {
    box-shadow: none;
    border: rem(1px) solid $hr-border-color;

    @include media-breakpoint-up(md) {
      .card-event__image {
        width: calc(45% - 1.5rem);
      }

      .card-event__main {
        padding-left: 45%;
        min-height: rem(1px);
      }
    }
  }

  .card-perk-discount {
    box-shadow: none;
    border: rem(1px) solid $hr-border-color;
  }
}

.benefit-item {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &:not(:last-child) {
    border-bottom: rem(1px) solid #EFEFEF;
    margin-bottom: rem(18px);
    padding-bottom: rem(18px);
  }

  &-title {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    //justify-content: start;
    flex-wrap: nowrap;
  }
}

.progress-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 320px;
  width: 100%;

  .progress {
    min-width: rem(157px);
    margin-right: rem(8px);
    margin-bottom: rem(10px);
    margin-top: rem(10px);
  }
}

.table-item-actions {
  .dropdown-item {
    height: auto;
    font-size: rem(16px);
    color: #23272B;

    &:focus {
      background-color: transparent;
    }

    i {
      font-size: rem(20px);
    }

    &:hover {
      background-color: transparent;

      i {
        color: $main-site-color;
      }
    }
  }
}