﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  1.00 Lists
-----------------------------------------
*/

.list-tags {
  margin: rem(-2px);
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.list-tags > li, .tag:not(.token) {
  display: flex;
  align-items: center;
  margin: rem(2px);
  background-color: #E4E4E4;
  border-radius: 0;
  color: #4B4B4B;
  padding: rem(4px) rem(12.5px);
  font-size: rem(12px);
  font-weight: 400;
  white-space: nowrap;
  flex-wrap: nowrap;
  cursor: default;

  @media only screen and (max-width: 767px) {
    height: rem(32px);
  }

  & > i {
    font-size: $input-btn-icon-size-xs;
  }

  &.with-icon-left {
    padding-left: $input-btn-padding-x-xs / 2;

    & > i {
      margin-right: $input-btn-padding-x-xs / 2;
    }
  }

  &.with-icon-right {
    padding-right: $input-btn-padding-x-xs / 2;

    & > i {
      margin-left: $input-btn-padding-x-xs / 2;
    }
  }

  &.bg-orange {
    background: $orange;
    color: $white;
  }

  &.bg-green {
    background: $green;
    color: $white;
  }

  &.bg-red {
    background: $red;
    color: $white;
  }

  &.bg-orange-02 {
    background: $orange-200;
  }

  &.bg-green-02 {
    background: $green-200;
  }

  &.bg-red-02 {
    background: $red-200;
  }
}

.list-inline-4 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: rem(-2px) !important;

  & > * {
    margin: rem(2px) !important;
  }

  &.w-auto {
    flex: 0 0 auto;
  }
}

.list-inline-8 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: rem(-4px) !important;

  & > * {
    margin: rem(4px) !important;
  }
}

.list-inline-16 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: rem(-8px) !important;

  & > * {
    margin: rem(8px) !important;
  }
}

.list-inline-32 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: rem(-8px) rem(-16px);

  @include media-breakpoint-down(md) {
    margin: rem(-4px) rem(-8px);
  }

  &.md-column {
    @include media-breakpoint-only(md) {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin: rem(-4px) 0;

      & > * {
        margin: rem(4px) 0;
      }
    }
  }

  &.sm-column {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      margin: rem(-4px) 0;

      & > * {
        margin: rem(4px) 0;
      }
    }
  }

  & > * {
    margin: rem(8px) rem(16px);

    @include media-breakpoint-down(md) {
      margin: rem(4px) rem(8px);
    }
  }
}

.list-grid {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: rem(-12px);

  & > li {
    display: block;
    margin: rem(12px);
    width: calc(100% - #{rem(24px)});
  }

  &--25 {
    & > li {
      width: calc(25% - #{rem(24px)});
    }
  }

  &--33 {
    & > li {
      width: calc(33.33% - #{rem(24px)});
    }
  }

  &--50 {
    & > li {
      width: calc(50% - #{rem(24px)});
    }
  }

  &--lg-33 {
    @include media-breakpoint-down(lg) {
      & > li {
        width: calc(33.33% - #{rem(24px)});
      }
    }
  }

  &--lg-50 {
    @include media-breakpoint-down(lg) {
      & > li {
        width: calc(50% - #{rem(24px)});
      }
    }
  }

  &--lg-100 {
    @include media-breakpoint-down(lg) {
      & > li {
        width: calc(100% - #{rem(24px)});
      }
    }
  }

  &--md-50 {
    @include media-breakpoint-down(md) {
      & > li {
        width: calc(50% - #{rem(24px)});
      }
    }
  }

  &--md-100 {
    @include media-breakpoint-down(md) {
      & > li {
        width: calc(100% - #{rem(24px)});
      }
    }
  }

  &--sm-50 {
    @include media-breakpoint-down(sm) {
      & > li {
        width: calc(50% - #{rem(24px)});
      }
    }
  }

  &--sm-100 {
    @include media-breakpoint-down(sm) {
      & > li {
        width: calc(100% - #{rem(24px)});
      }
    }
  }
}

.list-avatars {
  margin: 0 0 0 rem(12px);
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    display: flex;
    margin-left: rem(-12px);

    @for $i from 20 through 1 {
      &:nth-child(#{ $i }) {
        z-index: #{ 20 - ($i - 1) };
      }
    }

    &.rest {
      font-size: rem(18px);
      margin-left: rem(6px);
      font-weight: 500;
    }
  }
}

.list-unstyled-striped {
  margin: 0;
  padding: 0;
  list-style: none;

  & > li:nth-child(odd) {
    background: $white;
  }

  & > li:nth-child(even) {
    background: $gray-100;
  }
}