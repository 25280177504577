﻿// Responsive Scale
:root {
  --scale: 1;

  @include media-breakpoint-only(xxl) {
    --scale: 1.1;
  }

  @include media-breakpoint-only(lg) {
    --scale: 0.9;
  }

  @include media-breakpoint-only(md) {
    --scale: 0.8;
  }

  @include media-breakpoint-down(sm) {
    --scale: 0.7;
  }
}
