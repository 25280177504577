.our-breadcrumbs {
  background-color: #EFEFEF;
  padding: rem(21.5px) 0;

  .breadcrumb {
    position: relative;
    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
      //justify-content: space-between;
      &:before {
        content: '';
        height: rem(2px);
        background-color: #E4E4E4;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        z-index: 1;
        display: none;
      }
    }
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      padding-left: rem(6px);
    }

    a,
    span {
      font-size: rem(14px);
    }

    span {
      cursor: default;
    }

    &:not(.active) {
      a,
      span {
        color: $gray-new-700;
      }
    }

    &.active {
      a,
      span {
        color: var(--bg-color);;
        font-weight: 600;
      }

      & + .breadcrumb-item {
        &:before {
          color: $main-site-color;
        }
      }
    }

    @include media-breakpoint-up(md) {
      & + .breadcrumb-item {
        &:before {
          content: "\e9ae";
          font-family: "nexudus-icons-desktop" !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          -webkit-font-feature-settings: normal;
          -moz-font-feature-settings: normal;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          padding-right: 0;
          margin-right: rem(8px);
          font-size: rem(12px);
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0 !important;
      position: relative;
      z-index: 2;
      width: 100%;

      & + .breadcrumb-item {
        &:before {
          display: none;
        }
      }

      &:not(.active) {
        &:before {
          display: none;
        }

        &:before {
          content: '';
          width: rem(16px);
          height: rem(16px);
          display: inline-flex;
          background-color: #E4E4E4;
          border-radius: 50%;
          background-image: none !important;
          padding: 0;
        }

        &:not(:last-child):after {
          content: '';
          display: block;
          width: 100%;
          height: rem(2px);
          background-color: #E4E4E4;
          flex: 1;
        }

        span {
          font-size: 0;
        }

        &:last-child {
          width: auto;
        }
      }
      &.active {
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: rem(2px);
          background-color: #E4E4E4;
          flex: 1;
        }

        span {
          font-weight: 400;
          background-color: #EFEFEF;
          padding: 0 rem(4px);
        }

        &:first-child span {
          padding-left: 0;
        }

        &:last-child {
          width: auto;
        }

      }
      &.done {
        width: 25%;

        &:before {
          background-color: $component-active-bg;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNjk5NyAyLjI5Mjg5QzExLjA5MDIgMS45MDIzNiAxMS4wOTAyIDEuMjY5MiAxMC42OTk3IDAuODc4NjczQzEwLjMwOTIgMC40ODgxNDkgOS42NzYwMyAwLjQ4ODE0OSA5LjI4NTUgMC44Nzg2NzRMNC4zMzU3NiA1LjgyODQyTDIuMjE0NDQgMy43MDcxQzEuODIzOTEgMy4zMTY1OCAxLjE5MDc1IDMuMzE2NTggMC44MDAyMjQgMy43MDcxQzAuNDA5Njk5IDQuMDk3NjMgMC40MDk2OTkgNC43MzA3OSAwLjgwMDIyNCA1LjEyMTMxTDMuNjI4NjUgNy45NDk3NEM0LjAxOTE3IDguMzQwMjcgNC42NTIzNCA4LjM0MDI3IDUuMDQyODYgNy45NDk3NEwxMC42OTk3IDIuMjkyODlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K") !important;
          background-repeat: no-repeat;
          background-position: center;
        }

        &:after {
          background-color: $component-active-bg !important;
        }

        a {
          font-size: 0;
          width: 1rem;
          height: 1rem;
          position: absolute;
          z-index: 1;

          &:before {
            content: '';
            @include center_transform;
            width: rem(25px);
            height: rem(25px);
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    //overflow: hidden;
    padding: rem(17.5px) 0;
  }

}