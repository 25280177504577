﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 Dropdown
-----------------------------------------
*/

form {
  .dropdown {
    .dropdown-menu {
      //max-height: rem(280px);
      overflow: auto;
      @include scrollbar();
    }
  }
}

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
  padding: 0;

  &.notifications-menu {
    width: 30vw;

    @include media-breakpoint-down(md) {
      width: 50vw;
    }

    li > a {
      white-space: normal;
    }
  }

  .dropdown-menu-scrollable-list {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 50vh;
    overflow: auto;
    @include scrollbar();
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  height: $input-btn-height;
  border-radius: $btn-border-radius;
  font-weight: $font-weight-medium;
  font-size: $input-btn-font-size;
  line-height: 1;
  text-decoration: none;
  @include transition();

  & > i {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    margin-right: $input-btn-padding-x/2;
    font-size: $input-btn-icon-size;
  }

  &:focus,
  &:active {
    outline: none !important;
  }
}

.dropdown--notifications {
  .dropdown-menu {
    max-height: rem(280px);
    overflow: auto;
    @include scrollbar();
    border-radius: 0;

    li {
      .dropdown-item {
        display: block;
        height: auto;
        color: $gray-900;
        padding-top: rem(8px);
        padding-bottom: rem(8px);
        font-weight: 400;
        font-size: rem(12px);

        &:hover {
          i {
            color: $bg-color;
          }
        }

        i {
          color: $gray-600;
          width: rem(24px);
        }

        span {
          display: block;
          font-size: rem(10px);
          color: $gray-700;
          padding-top: rem(4px);
        }

        &.item {
        }

        &.action {
          padding-top: rem(6px);
          padding-bottom: rem(6px);
        }
      }

      hr {
        margin-top: rem(8px);
        margin-bottom: rem(8px);
      }
    }
  }
}
