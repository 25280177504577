﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  3.00 Signup
-----------------------------------------
*/

.login {

  &__header {
  }

  &__content {
    position: relative;
    display: block;
    min-height: calc(100vh - 80px);
    padding: rem(48px) rem(16px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($gray-900, 0.7);
    }

  }

  &__footer {
    .container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-top: rem(32px);
      padding-bottom: rem(32px);
      font-size: rem(14px);

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }

  &__card {
    margin: 0 auto;
    max-width: rem(620px);
    padding: rem(32px) rem(48px);

    @include media-breakpoint-down(sm) {
      padding: rem(32px) rem(16px);
    }

    &__header {
      text-align: center;

      h4 {
        margin: 0;
      }
    }

    &__content {
      display: block;
      padding: rem(24px) 0;

      .custom-control.custom-checkbox {
        margin-top: rem(20px);
        margin-bottom: rem(10px);
      }

      .credentials {
        &__social-access {
          margin-bottom: rem(32px);

          .btn {
            position: relative;
            display: flex;
            width: 100%;
            margin-top: rem(8px);

            i {
              position: absolute;
              top: 50%;
              left: rem(16px);
              transform: translateY(-50%);
              font-size: rem(24px);
            }

            span {
            }

            &--google {
              background-color: #4C8BF5;
              color: #fff;

              &:hover, &:active, &:focus {
                background-color: darken(#4C8BF5, 8%);
              }
            }

            &--facebook {
              background-color: #4267B2;
              color: #fff;

              &:hover, &:active, &:focus {
                background-color: darken(#4267B2, 8%);
              }
            }

            &--okta {
              background: $white;
              color: $gray-900;
              border: 1px solid $gray-600;
              box-shadow: none;

              &:hover, &:active, &:focus {
                background: $gray-100;
              }
            }
          }
        }

        &__register-access {
          &__intro {
          }
        }
      }
    }

    &__footer {
      text-align: center;
      font-size: rem(12px);
      font-weight: 500;
      color: $gray-700;

      a {
        color: $gray-700;
        text-decoration: underline;

        &:hover, &:active, &:focus {
          color: $gray-900;
        }
      }
    }
  }
}

.signup {
  padding: rem(48px) 0 rem(64px) 0;

  fieldset {
    position: relative;
    display: block;

    &:not(:last-child) {
      padding: 0 0 rem(24px) 0;
      margin: 0 0 rem(32px) 0;
      border-bottom: 1px solid $hr-border-color;
    }

    & > h5 {
      position: relative;
      display: block;
      font-weight: 700;
      font-size: $h5-font-size;
      line-height: 1.33;
      margin-bottom: rem(20px);
    }

    small {
      display: block;
      font-size: rem(12px);
      line-height: 1.3;
      color: $gray-600;
      margin: 0;
      margin-top: rem(8px);
    }
  }

  &__content {
    @extend .login__content;
  }

  &__card {
    @extend .login__card;

    &__header {
      @extend .login__card__header;
    }

    &__content {

      [data-component-name="SignupForm"] {
        max-width: none;

        & > fieldset {

          margin-bottom: rem(24px);

          & > h5 {
            text-align: center;
            margin-top: rem(32px);
            font-size: rem(16px);
            font-weight: 500;
          }
        }
      }

      & .card,
      & .card-item-body {
        padding: 0;
      }

      & .card-item-header {
        padding: 1rem 0;
        border: 0;
      }
    }
  }
}
