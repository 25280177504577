﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 FullCalendar
-----------------------------------------
*/

#fc-wrapper {
  &.loading {
    .fc-view-container {
      opacity: 0.3;
    }

    * {
      cursor: progress !important;
    }
  }


  .fc-timegrid-slot {
    height: 2rem;
  }

  .fc-timegrid-slot-label-frame {
    font-size: .875rem;
    font-weight: 400;
    border: 0;
    background-color: #f5f5f5;
    text-align: left;
  }

  .fc-scrollgrid {
    border: 0;
  }

  .fc-timegrid-slot-label {
    font-size: .875rem;
    font-weight: 400;
    border: 0;
    background-color: #f5f5f5;
    text-align: left;
    padding-right: 1.5rem;
  }

  .fc-scrollgrid-section-header {

    & > th,
    th.fc-timegrid-axis {
      background: transparent;
      border: 0;
    }

    th.fc-col-header-cell {
      border: 0;
      background-color: transparent;
      padding-bottom: rem(40px);
      @include media-breakpoint-down(sm) {
        font-size: rem(14px);
      }
    }
  }

  .fc-scrollgrid-section-body {
    & > td {
      border: 0 !important;
    }
  }

  .fc-timegrid-body {
    background-color: $white;
  }

  td.fc-daygrid-day {
    background-color: $white;
  }

  .fc-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $input-btn-height-md;
    font-weight: $btn-font-weight;
    background-color: transparent;
    color: #111111;
    border: 1px solid #E4E4E4;
    padding: $input-btn-padding-y $input-btn-padding-x;
    font-size: $input-btn-font-size;
    line-height: $btn-line-height;
    border-radius: $btn-border-radius;
    outline: 0 !important;
    box-shadow: none;
    @include transition();

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: transparent;
      color: $gray-900;
      outline: 0 !important;
      box-shadow: none;
    }

    &.fc-button-active {
      background-color: $gray-900;
      border-color: $gray-900;
      color: $white;
    }

    &.fc-button-primary {
      //background-color: $gray-100;
      //border-color: $gray-100;
      //color: $gray-600;

      &:hover,
      &:active,
      &:focus {
        background-color: $gray-300;
        border-color: $gray-300;
        color: #23272B;
      }

      &.fc-button-active {
        background-color: var(--bg-color);
        border-color: var(--bg-color);
        color: $white;
      }
    }

    &.fc-prev-button,
    &.fc-next-button {
      color: #111111;
      border: 1px solid #E4E4E4;
      box-shadow: $box-shadow-sm;
      margin-left: rem(4px);
      padding: 0;
      width: $input-btn-height;
      text-align: center;
      justify-content: center;

      &:hover,
      &:active,
      &:focus {
        background-color: $gray-300;
        border-color: $gray-300;
        color: $gray-900;
      }
    }

    &.fc-today-button {
      text-transform: capitalize;
      opacity: 1;
    }
  }

  /*--*/

  .fc-time-grid > .fc-bg,
  .fc-row .fc-bg {
    background-color: $white;
  }

  .fc-toolbar {
    h2 {
      font-size: rem(20px);
      font-weight: 500;
      text-transform: capitalize;

      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }

    .fc-button-group {
      border-left: 2px solid $gray-300;
      padding-left: rem(8px);
      margin-left: rem(12px) !important;
    }

    &.fc-header-toolbar {
      margin-bottom: rem(24px);
      margin-top: rem(20px);
      background-color: $white;
      padding: rem(18px) rem(36px);
      @include media-breakpoint-down(sm) {
        padding: rem(12px) rem(20px);
        flex-wrap: wrap;

        .fc-right {
          width: 100%;
          text-align: right;
          margin-top: rem(10px);
        }
      }

      @media only screen and (max-width: 580px) {
        flex-direction: column;

        .fc-center {
          margin-top: 10px;
        }
        .fc-right {
          text-align: center;
        }
      }
    }

    &.fc-resources-toolbar {
      margin-bottom: rem(20px);

      & > div.list-inline-4 {
        justify-content: flex-end;
      }
    }

    & > * > :not(:first-child) {
      margin-left: rem(4px);
    }
  }

  .fc-head-container {
    border: 0;
  }

  .fc-view-container {
    margin-top: rem(32px);
    //max-width: rem(850px);

    .fc-view {
      &.fc-timeline {
        .fc-cell-text {
          font-size: rem(14px);
        }
      }

      &.fc-timeGrid-view {
        .fc-widget-header {
        }

        .fc-axis {
          padding-right: rem(24px);

          @media only screen and (max-width: 580px) {
            padding-right: 6px;
          }

          &.fc-time {
            font-size: rem(14px);
            font-weight: 400;
            border: 0;
            background-color: #f5f5f5;
            text-align: left;
          }
        }
      }

      &.fc-dayGrid-view {
        .fc-widget-header {
        }

        .fc-widget-content {
          .fc-day-top {
            position: relative;

            .fc-day-number {
            }
          }
        }
      }

      .fc-body {
        & > tr > .fc-widget-content {
          border: 0;
        }

        &:before {
          content: '';
          height: rem(40px);
          display: block;
        }
      }

      .fc-time {
        & + .fc-widget-content {

        }
      }

      .fc-time-grid .fc-slats td {
        height: rem(32px);
      }
    }
  }

  /*--*/


  .fc-day-other {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAKklEQVQYV2NkYGCQYmBgeMaABhihfAxJmARIHkUSWQJFEl0CLolNAiwJAKjUBSHIEo60AAAAAElFTkSuQmCC) repeat;
  }

  tfoot {
    .fc-scrollgrid-section-sticky.fc-scrollgrid-section-footer th {
      position: static;
    }
  }

  @include media-breakpoint-down(sm) {
    .fc-header-toolbar {
      .fc-toolbar-chunk {
        margin-bottom: rem(10px);
      }
    }

    .fc .fc-daygrid-event-harness {
      overflow: auto;
    }
  }

  .fc-datagrid-cell-main {
    font-size: rem(14px);
  }

  .fc-datagrid-expander .fc-icon {
    display: none;
  }

  .fc-event {
    border: none;
    border-radius: $border-radius-sm;
    padding: rem(2px) rem(4px);
    margin: rem(2px) 0;
    cursor: pointer;
    font-size: rem(12px);
    font-weight: 500;

    &.fc-time-grid-event {
    }

    &.fc-day-grid-event,
    &.fc-daygrid-dot-event {
      position: relative;
      background-color: $gray-100 !important;
      border-color: $gray-100 !important;
      color: $gray-900 !important;
      display: flex;
      flex-direction: column;
      padding-left: rem(8px);
      align-items: flex-start;

      //.fc-content {


      .higlight-bar,
      .fc-daygrid-event-dot {
        position: absolute;
        top: rem(0px);
        left: rem(0px);
        bottom: rem(0px);
        display: block;
        width: rem(4px);
        border-radius: 0 !important;
        background-color: $gray-700;
        order: 0;
      }

      .fc-time,
      .fc-event-time {
        display: block;
        font-weight: 400;
        color: $gray-700;
        order: 2;
      }

      .fc-title,
      .fc-event-title {
        display: block;
        font-weight: 500;
        color: $gray-900;
        order: 1;
      }

      .fc-daygrid-event-dot {
        border: 0;
        border-left: .25rem solid transparent;
        width: auto;
        height: 100%;
      }

      .fc-event-title,
      .fc-event-time {
        padding-left: 0.5rem;
      }


      //}

      &:hover,
      &:active,
      &:focus {
        background-color: $gray-200 !important;
        border-color: $gray-200 !important;
      }

      &.fc-time-grid-event {
        .fc-content {
          flex-direction: row;
          align-items: center;

          .fc-time {
            padding-left: rem(4px);
            padding-top: 1px;
          }
        }
      }

      &.basket-booking {
      }
    }
  }

  .fc-daygrid-day-number {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin: 0.25rem;
    font-size: .875rem;
    font-weight: 500;
  }

  .fc-col-header,
  .fc-daygrid-body {
    width: 100% !important;
  }

  td.fc-day-today {
    background-color: rgba(0, 100, 250, .02) !important;

    .fc-daygrid-day-number {
      background: var(--bg-color);
      color: var(--text-color);
      border-radius: rem(150px);
    }
  }

  .fc-timeline {
    color: #000;

    & > table.fc-scrollgrid {
      background-color: $white;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 1em;
    }

    .fc-timeline-slot-frame {
      font-size: rem(14px);
    }

    .fc-datagrid-body {
      background-color: $white;
    }

    .fc-timeline-slot-label {
      border: 0;
    }
  }


  #fc-wrapper {
    table {
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 1em;
    }
  }

  .fc-header-toolbar {
    .fc-toolbar-chunk {
      &:first-child {
        .fc-button-group {
          border: 0;
          padding: 0;
          margin-left: 0 !important;

          .fc-button {
            &:not(:first-child) {
              margin-left: 0.25rem;
            }
          }
        }
      }
    }
  }

  .fc-day-header {
    &.fc-today {
      color: $gray-900;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    @media only screen and (max-width: 580px) {
      #fc-wrapper .fc-day-header {
        font-size: 12px;
        line-height: 1.2;
      }
    }
  }

  .fc-day {
    &.fc-other-month {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAKklEQVQYV2NkYGCQYmBgeMaABhihfAxJmARIHkUSWQJFEl0CLolNAiwJAKjUBSHIEo60AAAAAElFTkSuQmCC) repeat;
    }

    &.fc-today {
      background: rgba($blue, 2%);
    }
  }

  .fc-day-top {
    .fc-day-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(24px);
      height: rem(24px);
      border-radius: 50%;
      margin: rem(4px);
      font-size: rem(14px);
      font-weight: 500;
    }

    &.fc-today {
      .fc-day-number {
        background: $bg-color;
        color: $text-color;
      }
    }
  }

  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-content,
  .fc-unthemed .fc-popover,
  .fc-unthemed .fc-list-view,
  .fc-unthemed .fc-list-heading td {
    border-color: #EFEFEF;
  }

  .fc-scroller {
    @include scrollbar($white, 17px, 4px, rgba($gray-900, 0.33));
  }
}

.events-fullcalendar {
  @include media-breakpoint-up(md) {
    .fc-day-grid-container {
      overflow: visible !important;
    }
  }

  .fc-event {
    &.fc-time-grid-event {
      background-color: $bg-color;
      color: $text-color;
      border-color: $gray-100;
    }

    .tooltip {
      opacity: 1;
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 100%;
      max-width: initial;

      .tooltip-inner {
        text-align: left;
        min-width: 200px;
        max-height: 500px;
        overflow-y: auto;
      }

      .arrow {
        transform: translate(-50%, 0);
        left: 50%;
      }
    }
  }
}
