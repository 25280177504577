﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  1.00 Typography
-----------------------------------------
*/

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
}

a {
  @include transition();
  text-decoration: underline;
  text-decoration-color: transparent;

  &:hover, &:focus, &:active {
    text-decoration-color: currentColor;
  }
}

b, strong {
  font-weight: 600;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.33 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.fs-12 {
  font-size: rem(12px) !important;
}

.fs-lg-12 {
  @include media-breakpoint-down(lg) {
    font-size: rem(12px) !important;
  }
}

.fs-md-12 {
  @include media-breakpoint-down(md) {
    font-size: rem(12px) !important;
  }
}

.fs-sm-12 {
  @include media-breakpoint-down(sm) {
    font-size: rem(12px) !important;
  }
}

.fs-14 {
  font-size: rem(14px) !important;
}

.fs-16 {
  font-size: rem(16px) !important;
}

.fs-18 {
  font-size: rem(18px) !important;
}

.fs-20 {
  font-size: rem(20px) !important;
}

.fs-24 {
  font-size: rem(24px) !important;
}

.fs-40 {
  font-size: rem(40px) !important;
}

.fs-64 {
  font-size: rem(64px) !important;
}

.tdn {
  text-decoration: none !important;
  text-decoration-color: transparent !important;

  &:hover, &:focus, &:active, &.active {
    text-decoration: none !important;
    text-decoration-color: transparent !important;
  }
}

.tdu {
  text-decoration: underline !important;
  text-decoration-color: currentColor !important;
}

.tdhu {
  text-decoration: underline;
  text-decoration-color: transparent;

  &:hover, &:focus, &:active {
    text-decoration-color: currentColor;
  }
}

.outline-0 {
  outline: 0 !important;

  &:hover, &:active, &:focus {
    outline: 0 !important;
  }
}

.ws-nw {
  white-space: nowrap !important;
}

.ws-normal {
  white-space: normal !important;
}

.text-xl-right {
  @include media-breakpoint-up(xl) {
    text-align: right;
  }
}

.text-lg-right {
  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}

.text-md-right {
  @include media-breakpoint-up(md) {
    text-align: right;
  }
}
