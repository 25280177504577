:root {
  --border-radius: rem(4px);
}

[data-component-name="DefaultLayout"] {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.gray-new-primary {
  color: $gray-new-primary;
}

.content {
  flex: 1 0 auto;
}

.list-style-none {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sorting-item {
  span {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

body.li {
  .container {
    max-width: calc(100% - 96px);

    @include media-breakpoint-only(md) {
      max-width: calc(100% - 20px);
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
}

.portal-title {
  font-size: rem(20px);
  font-weight: 400;
  margin: rem(10px) 0;

  @media only screen and (max-width: 991px) {
    font-size: rem(18px);
  }

  @media only screen and (max-width: 480px) {
    font-size: rem(18px);
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #EFEFEF;
    min-height: rem(64px);
    padding: rem(5px) 0;
    margin-bottom: rem(48px);

    @include media-breakpoint-only(md) {
      margin-bottom: rem(20px);
    }

    @include media-breakpoint-down(sm) {
      padding: rem(13px) 0;
      min-height: auto;
      margin-bottom: rem(23px);
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.resource-filter-label {
  color: $black;
  font-size: rem(16px);
  margin-bottom: 0;
  margin-right: rem(8px);

  @media only screen and (max-width: 767px) {
    min-width: auto !important;
    font-size: rem(12px);
  }
}

.resources-type-view {
  background-color: transparent;
  color: #777777;
  text-align: left;

  &.active,
  &:hover,
  &:focus,
  &:active,
  &.active:hover {
    background-color: $white !important;
    color: #000 !important;
  }

  @media only screen and (max-width: 767px) {
    min-height: rem(28px) !important;
    border-bottom: 1px solid transparent;
    padding: 0 rem(10px) !important;
    margin: 0 rem(10px) !important;
    &.active {
      min-height: rem(28px) !important;
      border-color: #23272B;
    }
    &-wrapper {
      margin-top: rem(10px);
      width: 100%;
      background-color: $white;
      padding: rem(5px);
    }
    i {
      font-size: rem(16px) !important;
      margin-right: rem(5px) !important;
    }
    border-radius: 0 !important;
  }

  @media only screen and (max-width: 480px) {
    font-size: rem(12px) !important;
  }
}

.modal-booking-details-title {
  font-size: rem(18px);
  font-weight: bold;
  color: #23272B;
}

.rw-widget-picker {
  min-height: 3.5rem;
}

.booking-resource-info-list {
  i {
    margin-right: rem(17px);
    font-size: rem(22px);
    color: #444444;
  }

  li {
    display: flex;
    align-items: center;
    font-size: rem(14px);
  }
}

#BookingDiscountFormInputField {
  border: 1px solid #DDD;
  border-radius: 0;
  padding: 5px 10px;
  margin-right: rem(10px);
}

.book-now-button {
  .btn {
    font-size: 16px;
  }
}

.single-page-back {
  padding: rem(20px) rem(20px) rem(20px) 0;
  display: flex;
  align-items: center;
  color: #444444;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 rem(20px) 0 0;
  }
}

@include media-breakpoint-down(sm) {
  .book-now-button {
    .btn {
      font-size: 14px;

      i {
        font-size: 14px;
      }
    }
  }
}

.contact-information {
  &-items {
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &-item {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 rem(15px);
    margin-bottom: rem(15px);
    max-width: rem(280px);
    @include media-breakpoint-down(sm) {
      padding: 0;
      font-size: rem(14px);
    }

    i {
      font-size: rem(22px);
      padding-right: rem(20px);
    }
  }
}

.booking-resource-selector {
  background-color: #fff;

  .dropdown-toggle {
    h4 {
      font-size: rem(16px);
    }
  }
}

.thyp {
  &-icon {
    width: rem(114px);
    height: rem(114px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(65px);
    background-color: #F7F7F7;
    border-radius: rem(150px);
    margin-bottom: rem(58px);

    i {
      color: var(--bg-color);
    }
  }

  &-wrapper {
    background-color: #fff;
    padding: rem(71px) rem(36px) rem(36px);
    text-align: center;
  }
}

.checkout-page {
  .site-header__content--right,
  .locations-menu,
  .toggle-mobile {
    display: none !important;
  }

  .site-header__content {
    justify-content: center !important;
  }
}

[data-component-name="PaymentHistory"] {
  .table {
    margin-bottom: 0;
  }
}

.site-header__content--right {
  .dropdown-item {
    i {
      font-size: rem(19px);
    }
  }
}

.contact-details-price-wrapper {
  display: flex;
  flex-direction: column;
}

.card-divider {
  flex-grow: 1;
}

.toggle-desktop {
  i {
    color: $body-color;
  }
}

.meetings-header {
  align-items: flex-end;
}

.qr-code-img {
  img {
    width: 17rem;
  }
}

[data-component-name="HostedPaymentProviderButton"]:not(:first-child) {
  margin-top: 2rem;
}

@media only screen and (max-width: 992px) {
  .booking-header {
    position: static;
  }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .meetings-header {
    li:not(#filter-search-button) {
      width: 31%;
    }

    .form-group {
      width: 100%;

      & .rw-dropdown-list,
      .rw-date-picker {
        width: 100%;
      }
    }

    & > form {
      li {
        &:not(:last-child) {
          width: calc(25% - 10px);
        }

        &:last-child {

          button {
            width: 100%;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

}

@media only screen and (min-width: 768px) and (max-width: 1480px) {
  .meetings-header {
    justify-content: center;

    .form-group {
      flex-direction: column;
      align-items: flex-start;

      .form-control,
      .form-group-sm {
        min-height: 2.5rem;
      }

      .form-group-sm {
        min-width: 160px;
      }
    }

    form {
      li {
        width: calc(25% - 10px);
      }

      .form-group {
        width: 100%;

        .rw-date-picker,
        .rw-dropdown-list {
          width: 100%;
        }
      }
    }

    & > form {
      li {
        &:last-child {
          button {
            width: 100%;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .custom-select {
      height: 2.5rem;
      width: 100%;
    }

    ul {
      align-items: flex-end;
      justify-content: space-between;
    }

    li {
      flex-direction: column;
      margin: rem(5px);
    }

    .resource-filter-label {
      width: 100%;
      display: block;
      margin-right: 0;
    }
  }
}

.toggle-mobile {
  i {
    color: $body-color !important;
  }
}

.content--fullpage {
  padding-bottom: rem(85px);
}

.ReactModalPortal {
  position: relative;
  z-index: 9999;
}

@include media-breakpoint-down(sm) {
  .meetings-header {
    ul {
      justify-content: center;
    }

    form {


      .form-group {
        flex-direction: column;
        justify-content: flex-start;

        .rw-dropdown-list {
          width: 100%;
        }

        .control-label {
          min-width: auto !important;
          font-size: 12px !important;
          width: 100% !important;
          margin: 0 !important;
        }
      }
    }
  }
}

.site-header--lo .site-header__content .navbar .navbar-brand img {
  width: auto!important;
  max-height: 3rem;
  height: auto!important;
  max-width: 100%;
}

nav.container.navbar.navbar-expand-lg {
  flex-wrap: nowrap;
}
