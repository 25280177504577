@import "../utils/mixins";
@import "../variables";
/*
-----------------------------------------
  1.00 Carousel
-----------------------------------------
*/

.carousel {
  &--about {
    .carousel-control-prev, .carousel-control-next {
      font-size: rem(32px);
    }

    .img {
      height: 75vh;
      min-height: 540px;
      max-height: 1200px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: $border-radius;

      @include media-breakpoint-down(md) {
        height: 65vh;
        min-height: 180px;
      }
    }
  }
}
