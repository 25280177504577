.rw {
  &-popup {
    border-radius: 0;
    border-color: #EFEFEF;
    box-shadow: 0 rem(4px) rem(15px) #efefef;
  }

  &-head-cell {
    border: 0;
  }

  &-calendar {
    &-head {
      color: #E4E4E4;
    }

    &-header {
      .rw-btn {
        & > svg {
          fill: #777
        }
      }
    }

    &-btn-view {
      font-weight: 400;
    }

    &-body {
      .rw-state-selected {
        background-color: $main-site-color;
        border-color: $main-site-color;
        border-radius: 0;
      }
    }
  }
}